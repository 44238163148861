import React from "react";
import * as Yup from "yup";
import logoForm from "../../assets/images/logo-form.png";
import { Formik } from "formik";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";
import Modal from "bootstrap/js/dist/modal";
import { EMAIL_REGEX } from "../../utils/Constants";
import { MESSAGE } from "../../utils/ValidationMessage";
import { Error } from "../../utils/Alert";

const ForgotPassword = () => {
  const initialValues = { email: "" };

  const validationSchema = Yup.object().shape({ email: Yup.string().matches(EMAIL_REGEX, MESSAGE.EMAIL).max(255).required("Email is required").trim() });

  const onSubmit = async (values,{ resetForm }) => {
    const myModal = document.getElementById("password-link-sent");
    const modal = new Modal(myModal);

    const Modalforgot = document.getElementById("forget-password");
    const forgotmodal = new Modal(Modalforgot);
    console.log(forgotmodal);
    try {
      const response = await AuthService.forgotPassword(values);
      forgotmodal.toggle();
      // console.log(forgotmodal)
      modal?.show();
      setTimeout(()=> {forgotmodal?.hide();modal?.hide();resetForm(); },2000);
    } catch (error) {
      const { message } = error?.response?.data;
      Swal.fire({ text: message, ...Error});
    }
  };

  return (
    <div className="modal fade common-modal" id="forget-password" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-head text-center">
            <figure>
              <img src={logoForm} alt="logo" />
            </figure>
            <h5 className="modal-title" id="exampleModalLabel">
              Forgot Password
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i className="ti ti-x"></i>
            </button>
          </div>
          <div className="modal-body">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-4 pb-2">
                    <label className="custom-field one">
                      <input placeholder="Email Address" type="text" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                      <p className="text-danger">{errors.email && touched.email && errors.email}</p>
                      <i className="ti ti-mail" />
                    </label>
                  </div>
                  <div>
                    <button type="submit" data-bs-target="password-link-sent" disabled={isSubmitting} className="btn btn-primary full-btn hvr-sweep-to-right">
                      Reset Password
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
