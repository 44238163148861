import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../../features/eventSlice";
import { Link, useNavigate } from "react-router-dom";
import myprofile from "../../../assets/images/DefaultUserIcon.png";
import { PostDescription, PostGroups } from "../postBlocks";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import { BASE } from "../../../utils/Constants";
import Swal from "sweetalert2";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { Error, Note, Success } from "../../../utils/Alert";
import * as Yup from "yup";

const Buy = () => {
  const { data: post, buy, payment } = useSelector((state) => state?.event);
  const { adminInfo, profile } = useSelector((state) => state?.auth);
  const validationSchema = Yup.object().shape({
    quantity: Yup.string().required(MESSAGE.REQUIRED),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => dispatch(close("buy"));

  let admin = adminInfo?.instituteid === post?.instituteid;
  let member = profile?.roledata.find((x) => x?.instituteid === post?.instituteid);
  let teacher = member?.role === "teacher";






  const initialValues = {
    accept_payment : payment?.accept_payment,
    stripe_account_id : payment?.stripe_account_id,
    event_type: "merchandise",
    instituteid: post?.instituteid,
    postid: post?.id,
    description: post?.description,
    memberid: member?.memberid,
    userid: profile?.userid,
    quantity: undefined,
    size: "",
    color: "",
    comment: "",
    price: post?.price,
    sales_tax : post?.sales_tax,
    // group_name: post?.grps[0] ? post.grps.filter(Boolean).map((item) => item.group_name) : [],
    group_name: post?.grps ? post?.grps?.filter(Boolean).map((item) => item?.group_name) : [],
    cardData: true,
    stripe_account_id: member?.stripe_account_id
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleClose();
      navigate("/payment", { state: { details: { ...values, price: (values?.price * values?.quantity)+values?.sales_tax } } });
    },
  });

  const modalbuyContent = post?.postevent[0] ? post?.postevent?.map((data, index) => {
    const user = post?.eventusers[0] && post?.eventusers?.find((e) => e?.id === data?.userid);

    return (
      <tr key={"modal-buy-content-" + index}>
        <td>
          <div className="List-Attendees-user d-flex align-items-center">
            <figure>
              <img 
                  className="avatarimage" 
                  src={user?.profilepic ? BASE + "/" + user?.profilepic : myprofile} 
                  alt="icon" 
                  onError={(e) => {
                    e.target.src = myprofile; 
                  }}
              />
            </figure>
            <h4>{user ? user?.fullname : "NA"}</h4>
          </div>
        </td>
        <td>{data?.quantity}</td>
        <td>{data?.size}</td>
        <td>{data?.color}</td>
        <td>{data?.price.toFixed(2)}</td>
        <td>{data?.confirmPaymentId ?? ""}</td>
        <td>{data?.comment}</td>
      </tr>
    );
    
  }) : null;

  let content = null;
  if (admin || teacher) {
    let totalprice = post?.postevent[0]
      ? parseFloat(post?.postevent.reduce((accumulator, currentObject) => {
          return accumulator + currentObject?.price;
        }, 0).toFixed(2))
      : 0.00;
  
    totalprice = totalprice.toFixed(2);
  

    content = (
      <>
        <Modal.Header className="px-4 border-0" closeButton>
          <Modal.Title className="ms-auto event-title">Buy</Modal.Title>
        </Modal.Header>
        <div className="modal-body px-5">
          <div className="all-posts-box similar-shadow-3">
            <h4>
              {post?.title}
              <Link to="#" className="calender-box">
                <i className="ti ti-calendar"></i>
              </Link>
            </h4>
            <PostGroups data={post} />
            <PostDescription data={post} />
          </div>
          <hr className="border-grey w-50 d-flex mx-auto" />
          <div className="row ">
            <div className="col-md-12 text-center">
              <div className="adult-info grey-bg d-flex justify-content-between align-items-center pt-3 pb-3">
                <h4 className="mb-0"> Total Transaction Amount</h4>
                <div className="adult-info-num d-flex justify-content-center">
                  <span>
                    <b>${totalprice}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h4 className="sub-title mb-3 mt-4">List of Transactions</h4>
          <div className="over-table">
            {post?.postevent[0] ? (
              <table width="100%" className="list-attendees">
                <tbody>
                  <tr>
                    <th>Member Name</th>
                    <th>Quantity</th>
                    <th>Size</th>
                    <th>Color</th>
                    <th>Paid</th>
                    <th>Confirmation#</th>
                    <th>Comments</th>
                  </tr>
                  {modalbuyContent}
                </tbody>
              </table>
            ) : (
              <div className="text-center">No data found</div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    content = (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <Modal.Header className="px-4 border-0" closeButton>
            <Modal.Title className="ms-auto event-title">Buy Merchandise</Modal.Title>
          </Modal.Header>
          <div className="modal-body px-5">
            <div className="pay-box-content mb-4">
              <h4>Description</h4>
              <PostDescription data={post} />
            </div>
            <hr className="grey-line" />
            <div className="mb-4">
              <Field as="select" name="quantity" className="form-control text-dark">
                <option value="">Quantity</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </Field>
              <ErrorMessage component="p" className="text-danger" name="quantity" />
            </div>
            <div className="mb-4">
              <Field as="select" name="size" className="form-control text-dark">
                <option value="">Size</option>
                {post?.size?.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </Field>
              <ErrorMessage component="p" className="text-danger" name="size" />
            </div>
            <div className="mb-4">
              <Field as="select" name="color" className="form-control text-dark">
                <option value="">Color</option>
                {post?.colours?.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </Field>
            </div>

            <div className="mb-4 textarea-box">
              <Field as="textarea" className="form-control" name="comment" placeholder="Add a comment" />
              <ErrorMessage component="p" className="text-danger" name="comment" />{" "}
            </div>
            <br></br>

            {formik?.values?.quantity && 
            <>
            <div className="pay-box-content d-flex justify-content-between">
              <h4><p>Price</p></h4>
              <p>
                <b className="blue-text">${(post?.price * formik?.values?.quantity).toFixed(2)} </b>
              </p>
            </div>
            <div className="pay-box-content  d-flex justify-content-between">
              <h4><p>Sales Tax</p></h4>
              <p>
                <b className="blue-text">${formik?.values?.sales_tax} </b>
              </p>
            </div>
            <div className="pay-box-content d-flex justify-content-between">
              <h4>Total Amount</h4>
              <p>
                <b className="blue-text">${((post?.price * formik?.values?.quantity)+formik?.values?.sales_tax).toFixed(2)} </b>
              </p>
            </div>
            </>
            }
            


            <div className="btn-group-modal mt-4">
              <button type="submit"   className="btn full-btn hvr-sweep-to-right">
                Pay
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    );
  }
  return (
    <Modal size={admin || teacher ? "xl" : "md"} centered show={buy} onHide={handleClose} keyboard={false}>
      {content}
    </Modal>
  );
};

export default Buy;
