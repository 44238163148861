import { useEffect, useState } from "react";
import Finance from "../../services/finance.service";
import { useSelector } from "react-redux";
import DateFormat from "../../utils/DateFormat";
import { PostMedia } from "../../components/post/postBlocks";

const Report = () => {
  const { adminInfo } = useSelector((state) => state?.auth);
  const [data, setData] = useState([]);

  useEffect(() => {
    Finance.report({ instituteid: adminInfo?.instituteid })
      .then((res) => setData(res?.data?.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <ul>
      {data?.map((item, index) => (
        <li key={index} className="all-posts-box similar-shadow-3 campaign-report-box">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
          <PostMedia data={item} />
          <div className="campaign-report-info d-flex mt-3 justify-content-between">
            <div className="d-flex flex-column">
              <div>
                <b>Created on:</b>
              </div>
              <div>{DateFormat(item?.created_at)}</div>
            </div>
            <div className="d-flex flex-column">
              <div>
                <b>Posted on:</b>
              </div>
              <div>{item?.approved ? DateFormat(item?.start_date) : "NA"}</div>
            </div>
            <div className="d-flex flex-column">
              <div>
                <b>Last Ad Date:</b>
              </div>
              <div>{item?.approved ? DateFormat(item?.end_date) : "NA"}</div>
            </div>
            <div className="d-flex flex-column">
              <div>
                <b>Members sent:</b>
              </div>
              <div>{item?.sent_count}</div>
            </div>
            <div className="d-flex flex-column">
              <div>
                <b>Number of clicks:</b>
              </div>
              <div>{item?.view_count}</div>
            </div>

            <div className="d-flex flex-column">
              <div>
                <b>Amount Paid:</b>
              </div>
              <div>${item?.price}</div>
            </div>
            <div className="d-flex flex-column">
              <div>
                <b>Approved:</b>
              </div>
              <div>{item?.approved ? "Yes" : "No"}</div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Report;
