import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../utils/RoleCheck";

const Receiver = ({ show, setShow, data, setReceipient, selectedUser, setSelectedUser, instituteid }) => {
  const { profile } = useSelector((state) => state?.auth);
  const [filter, setFilter] = useState(selectedUser?.role ?? "admin");
  const [user, setUser] = useState(selectedUser ? selectedUser : {});
  console.log({filter, selectedUser})

  const handleChange = (row) => setUser(row);

  const handleClose = () => setShow(false);

  const allData = data
    .filter((item) => item?.instituteid === instituteid)
    .filter((item) => item?.role === filter)
    .sort((a, b) => a?.fullname?.toLowerCase().localeCompare(b?.fullname?.toLowerCase()))
    .map((row, i) => (
      <label key={i} className="radio-coustom-design receiver-group select-group radio-box">
        <input className="form-check-input" type="radio" name={filter} value={row?.memberid} checked={user?.memberid === row?.memberid} onChange={() => handleChange(row)} />
        <p>
          {row?.fullname}
          <small className="d-flex align-items-center">
            <i className="ti ti-building"></i>
            {row?.institutename}
          </small>
        </p>
      </label>
    ));

  const handleSubmit = () => {
    // setReceipient("instituteid", user.instituteid);
    setReceipient("receiver_id", user?.userid);
    setReceipient("receiver_name", user?.fullname);
    // let added_by = getUserInfo(profile?.roledata, selectedUser?.instituteid);
    // setReceipient("added_by_role", added_by?.role);
    setSelectedUser(user);
    setShow(false);
  };

  return (
    <Modal contentClassName="border-0" centered show={show} onHide={handleClose}>
      <Modal.Header className="px-4 border-0" closeButton />
      <div className="modal-body">
        <h5 className="ms-auto event-title text-center mb-4">Select Receiver</h5>
        <div className="modal-tabs">
          <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
            <li className="nav-item">
              <button className={`nav-link ${filter === "admin" && "active"}`} type="button" onClick={() => setFilter("admin")}>
                Admins
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${filter === "teacher" && "active"}`} type="button" onClick={() => setFilter("teacher")}>
                Teachers
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${filter === "parent" && "active"}`} type="button" onClick={() => setFilter("parent")}>
                Class Parents
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${filter === "member" && "active"}`} type="button" onClick={() => setFilter("member")}>
                Members
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" role="tabpanel">
              <div className="select-receiver-box manage-groups-list">{allData}</div>
            </div>
          </div>
        </div>
        <div className="modal-btn-group mt-4">
          <button type="button" className="btn full-btn hvr-sweep-to-right" data-bs-dismiss="modal" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Receiver;
