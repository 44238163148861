import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import profileImg from "../../../assets/images/DefaultUserIcon.png";
import SchoolAdmin from "../../../services/admin.service";
import { BASE } from "../../../utils/Constants";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Error, Note } from "../../../utils/Alert";
import { Spinner } from "react-bootstrap";

const Profile = () => {
  const auth = useSelector((state) => state?.auth);
  const [data, setData] = useState({});

  useEffect(() => {
    SchoolAdmin.viewInstitute()
      .then((response) => {
        console.log(response?.data);
        setData(response?.data);
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
      });
  }, []);

  return (
    <>
      <div className="wraper-inner bg-grey space-top">
        <section className="profile pt-4 pb-5 min-box">
          <div className="container">
            <div className="row">
              <div className="col-md-5 m-auto">
                <div className="similar-shadow-box pb-5">
                  <h3 className="inner-title mb-4 text-center">Profile</h3>
                  <form>
                    {data?.firstname ?  <>
                    <div className="text-center profile-head">
                      <figure>
                        <img 
                            src={data?.profilepic ? `${BASE}/${data?.profilepic}` : profileImg} 
                            alt="logo" 
                            onError={(e) => {
                              e.target.src = profileImg; 
                            }}  
                        />
                      </figure>
                      <figcaption>
                    
                        <h4>
                          {data?.firstname} {data?.lastname}
                        </h4>
                        <span className="d-flex align-items-center justify-content-center">
                          <i className="ti ti-users"></i> {data?.group?.length ? data?.group?.length : "0"} Group(s)
                        </span>
                      </figcaption>
                    </div>
                    <ul className="profile-list mt-4">
                      {auth?.logintype?.includes("admin") && (
                        <>
                          <li>
                            <i className="ti ti-building"></i>
                            {data?.institutename ? data?.institutename : "NA"}
                          </li>
                          <li>
                            <i className="ti ti-user-circle"></i>
                            {data?.acronym ? data?.acronym : "NA"}
                          </li>
                          <li>
                            <i className="ti ti-map-pin"></i>
                            {data?.streetAddress && data?.city && data?.state ? `${data?.streetAddress.trim()}, ${data?.city}, ${data?.state}` : "NA"}
                          </li>
                        </>
                      )}
                      <li>
                        <i className="ti ti-mail"></i>
                        {data?.email ? data?.email : "NA"}
                      </li>
                      <li>
                        <i className="ti ti-phone"></i>
                        {data?.phoneno ? data?.phoneno : "NA"}
                      </li>
                    </ul>
                    <div className="btn-box mt-5">
                      <Link to="/edit-profile" className="btn full-btn hvr-sweep-to-right">
                        Edit Profile
                      </Link>
                    </div>
                    </>

                : <div style={{textAlign:"center"}}><Spinner animation="border" variant="success" /> </div>  }

                  
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Profile;
