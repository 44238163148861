import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ArchivedIcon from "../../../assets/images/archived-icon.png";
import DeleteIcon from "../../../assets/images/delete-icon.png";
import GroupArchived from "../../../assets/images/Group-Archived-img.png";
import useDebounce from "../../../hooks/useDebounce";
import SchoolAdmin from "../../../services/admin.service";
import { Error, Info, Note } from "../../../utils/Alert";

const Manage = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const [data, setData] = useState([]);
  const [selectedID, setSelectedID] = useState({});

  const [archived, setArchived] = useState(false);

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [refresh, setRefresh] = useState(false);

  const handlePageChange = (pageNo) => setPage(pageNo);

  const handlePerRowsChange = (newPerPage, page) => setPerPage(newPerPage);

  const handleDelete = () => {
    SchoolAdmin.delGroup(selectedID)
      .then(() => {
        Swal.fire({ text: Note.GroupDeleted, ...Info });
        setRefresh(!refresh);
      })
      .catch((error) => console.error(error));
  };

  const handleArchive = () => {
    SchoolAdmin.addRemoveArchieve({ ...selectedID, archieve: true })
      .then((res) => {
        Swal.fire({ text: Note.GroupArchived, ...Info });
        setRefresh(!refresh);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    SchoolAdmin.getAllGroups({ instituteid: auth?.adminInfo?.instituteid, search: search, current_page: page, archieve: archived })
      .then((res) => {
        setData(res?.data?.data?.found);
        setTotalRows(res?.data?.data?.total);
        setTotalPages(res?.data?.data?.totalPages);
      })
      .catch((error) => {
        const { message } = error?.response?.data;
        Swal.fire({ text: message, ...Error });
      });
  }, [archived, search, page, refresh]);

  const getNumberOfPages = (rowCount, rowsPerPage) => Math.ceil(rowCount / rowsPerPage);

  function toPages(pages) {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  }

  const CxPagination = ({ rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
      setPage(currentPage - 1);
    };

    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
      setPage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target?.value));
      setPage(Number(e.target?.value));
    };

    const pageItems = toPages(totalPages);
    const nextDisabled = currentPage === totalPages;
    const previosDisabled = currentPage === 1;

    return (
      <>
        {totalPages > 1 ? (
          <div className="d-flex justify-content-center">
            <ul className="pagination">
              <li className={`page-item ${previosDisabled ? "disabled" : ""}`}>
                <button className={`page-link`} onClick={handleBackButtonClick} disabled={previosDisabled} aria-disabled={previosDisabled} aria-label="previous page">
                  <span aria-hidden="true">
                    <i className="ti ti-arrow-narrow-left"></i>
                  </span>
                </button>
              </li>
              {pageItems?.map((pageNo) => {
                const className = pageNo === page ? "page-item active" : "page-item";
                return (
                  <li key={pageNo} className={className}>
                    <button className="page-link" onClick={handlePageNumber} value={pageNo}>
                      {pageNo}
                    </button>
                  </li>
                );
              })}
              <li className={`page-item ${nextDisabled ? "disabled" : ""}`}>
                <button className="page-link" onClick={handleNextButtonClick} disabled={nextDisabled} aria-disabled={nextDisabled} aria-label="next page">
                  <span aria-hidden="true">
                    <i className="ti ti-arrow-narrow-right"></i>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </>
    );
  };

  const handleSelection = (row) => setSelectedID({ id: row?.group_id, instituteid: row?.instituteid });

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.group_name,
      style: { flex: "1" },
      cell: (row) => (
        <div className="manage-groups-list-box-L">
          <Link to="/posts" state={{ id: row?.group_id, instituteid: row?.instituteid, group_name: row?.group_name, showCrumbs: true }} style={{ textDecoration: "none" }}>
            <h4>{row?.group_name}</h4>
          </Link>
          <div className="manage-groups-info">
            <span>
              <b>Posts: </b>
              {row?.post_count}
            </span>
            <span>
              <b>Members: </b>
              {row?.member_count - 1}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Actions",
      style: { display: "flex", justifyContent: "flex-end", flex: "initial" },
      cell: (row) => (
        <div className="manage-groups-list-box-R d-flex">
          {!archived ? (
            <button data-bs-toggle="modal" data-bs-target="#archived-post" style={{ border: "none" }} className="achived-box black-btn action-box" onClick={() => handleSelection(row)}>
              <img src={ArchivedIcon} alt="Archived" />
            </button>
          ) : null}
          <button data-bs-toggle="modal" data-bs-target="#delete-post" style={{ border: "none" }} className="delete-box blue-bg action-box ms-2" onClick={() => handleSelection(row)}>
            <img src={DeleteIcon} alt="delete" />
          </button>
          <button style={{ border: "none" }} className="delete-box blue-bg action-box ms-2" onClick={() => navigate("/edit-group", { state: { group_id: row?.group_id } })}>
            <i className="ti ti-pencil"></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="wraper-inner bg-grey  space-top">
        <section className="manage-groups pt-4 pb-5 min-box">
          <div className="container">
            <div className="row">
              <div className="similar-shadow-box pb-5 col-md-7 m-auto">
                <h3 className="inner-title mb-4">Manage Groups</h3>
                <div className="search-box mt-4 mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here.."
                    onChange={(e) => {
                      setSearch(e.target?.value);
                      setPage(1);
                    }}
                  />
                  <button className="serch-btn">
                    <i className="ti ti-search"></i>
                  </button>
                </div>
                <div className=" d-flex align-items-center justify-content-between mb-3">
                  {archived ? (
                    <a className="blue-link align-items-center" onClick={() => setArchived(false)}>
                      <img src={ArchivedIcon} className="me-2" alt="archived" />
                      Hide Archived
                    </a>
                  ) : (
                    <a className="blue-link align-items-center" onClick={() => setArchived(true)}>
                      <img src={ArchivedIcon} className="me-2" alt="archived" />
                      Show Archived
                    </a>
                  )}
                  <button className="btn m-btn hvr-sweep-to-right" onClick={() => navigate("/create-groups")}>
                    <i className="ti ti-circle-plus"></i>Create Group
                  </button>
                </div>
                <ul className="manage-groups-list">
                  <DataTable
                    data={data}
                    columns={columns}
                    noHeader
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationComponent={CxPagination}
                  />
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- archived-post --> */}
      <div className="modal fade common-modal" id="archived-post" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center">
              <p>Are you sure you want to archive the group? Group can only be unarchived by the Notetify team.</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <button className="btn full-btn hvr-sweep-to-right black-btn me-2" data-bs-dismiss="modal">
                  No
                </button>
                <button className="btn full-btn hvr-sweep-to-right ms-2" data-bs-dismiss="modal" onClick={() => handleArchive()}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Group Archived --> */}
      <div className="modal fade common-modal" id="group-archived" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center mb-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="box-content text-center">
                <figure className="mb-4">
                  <img src={GroupArchived} alt="archived" />
                </figure>
                <p>Group Archived</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- delete-post --> */}
      <div className="modal fade common-modal" id="delete-post" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center">
              <p>Are you sure you want to delete the group? Once deleted, group cannot be recovered. This also deletes all posts and members within the group.</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <button className="btn full-btn hvr-sweep-to-right black-btn me-2" data-bs-dismiss="modal">
                  No
                </button>
                <button className="btn full-btn hvr-sweep-to-right ms-2" onClick={() => handleDelete()} data-bs-dismiss="modal">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manage;
