import { useState } from "react";
import { likeDislikePost } from "../../features/postSlice";
import { open } from "../../features/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import { PostPermission } from "../../utils/Permissions";
import { PostTabs, PostMedia, PostGroups, PostDescription, PostComments, PostInteraction, PostOps, PostTitle } from "./postBlocks";
import Swal from "sweetalert2";
import { Info, Error, Note, Success } from "../../utils/Alert";


const MerchandisePost = ({ data }) => {
  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false);
  let { showEdit, showDelete, showEvent } = PostPermission("merchandise", data);
  const { profile } = useSelector((state) => state?.auth);
  const [likebuttonclicked, setLikeClicked] = useState(false);


  const handleLike = () => {
    if (!likebuttonclicked) {
      setLikeClicked(true);


      dispatch(likeDislikePost({ postid: data?.id, title: data?.title, likeflag: data?.likeflag ? true : false }))
        .finally(() => setLikeClicked(false));
    }
  };

  const handleRemindEvent = (event) => dispatch(open({ event,id:data?.id, data }));

  const handleEvent = (event) => {

    let member = profile?.roledata?.find((item) => item?.instituteid === data?.instituteid);
    let login_user_id = profile?.id;
    let is_login_user_already_paid = data?.eventusers?.filter(Boolean).find((x) => x?.id === login_user_id);

    let message = null;
    if (member?.stripe_account_id == null || member?.stripe_account_id == "") {
      message =
        member?.role === "admin"
          ? "Financial transactions are not enabled. Please add your bank or debit card information."
          : "Financial transactions are not enabled. Please contact your Administrator.";
    }

    if (message != null) {
      Swal.fire({ text: message ?? Note.Err, ...Error });
      return false;
    }


    if (event == 'buy' && is_login_user_already_paid) {
      Swal.fire({ text: "Payment has already been made. Multiple payments are not allowed." ?? Note.Err, ...Error });
      return false;
    }


    dispatch(open({ event, data, member }));




  };

  return (
    <div className="all-posts-box similar-shadow-2 mb-3 border-box">
      <PostOps data={data} showEdit={showEdit} showDelete={showDelete} />
      <PostTitle data={data} />
      <PostGroups data={data} />
      <PostTabs data={data} />
      <PostDescription data={data} />
      <PostMedia data={data} />
      <table width="100%" className="post-table mb-3 mt-0">
        <tbody>
          <tr>
            <th>Price</th>
            <th>Sales Tax</th>
            <th>Size</th>
            <th>Colors</th>
          </tr>
          <tr>
            <td>$ {data?.price}</td>
            <td>$ {data?.sales_tax}</td>
            <td>{`${data?.size}`.split(",").join(", ")}</td>
            <td>{`${data?.colours}`.split(",").join(", ")}</td>
          </tr>
        </tbody>
      </table>
      <PostInteraction data={data}>
        <ul className="d-flex">
          <li className={data?.likeflag ? "blue-thumb" : ""} onClick={handleLike}>
            <button>
              <i className="ti ti-thumb-up"></i> {data?.like_count}
            </button>
          </li>
          {!data?.disable_comments && (
            <li>
              <button onClick={() => setShowComment(!showComment)}>
                <i className="ti ti-message-dots"></i> {data?.comment_count}
              </button>
            </li>
          )}
        </ul>
        <div className="d-flex align-items-center bell-noti-main">
          {showEvent && <button onClick={() => handleEvent("buy")}>BUY</button>}
          {!data?.dmpost &&
            <button onClick={() => handleRemindEvent("remindMe")} className={data?.tags[0]?.remind_me ? "bell-noti-blue ms-2" : "bell-noti ms-2"} >
              <i className="ti ti-bell-ringing"></i>
            </button>
          }
        </div>
      </PostInteraction>
      {!data?.disable_comments && showComment && <PostComments data={data} />}
    </div>
  );
};

export default MerchandisePost;
