import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { currentPage, getAllDMPosts, getAllGroupPosts, getAllMemberOfSelectedGroup, getAllParticipatedGroups, getAllPosts } from "../features/postSlice";
import { DOTS } from "../hooks/usePagination"; // Assuming DOTS is defined elsewhere

export const PaginationBlock = () => {
  const { state, pathname } = useLocation();
  const { pageNumber: active, searched, totalPages } = useSelector((state) => state?.posts);
  const { allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    const element = document.getElementById("top");
    element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }, [active]);

  const onClickHandler = (page) => {
    dispatch(currentPage(page));
    if (pathname === "/sendmessage") {
      dispatch(getAllDMPosts({ current_page: page, institutes: allInstitutesIds }));
    } else if (pathname === "/home") {
      const adminInstitute = profile?.roledata?.filter((item) => item.role === "admin").map((item) => item?.instituteid);
      dispatch(getAllPosts({ institutes: allInstitutesIds, search: searched, current_page: page, adminInstitute }));
    } else {
      dispatch(getAllGroupPosts({ groupid: state?.id, search: searched, current_page: page }));
    }
  };

  const renderPaginationItems = () => {
    let items = [];

    // Calculate pagination range
    const siblingCount = 1;
    const boundaryCount = 1;
    let startPage = Math.max(1, active - siblingCount);
    let endPage = Math.min(totalPages, active + siblingCount);
    const leftBoundary = active <= boundaryCount + siblingCount + 1;
    const rightBoundary = active >= totalPages - (boundaryCount + siblingCount);

    if (!leftBoundary) {
      items?.push(
        <Pagination.Item key={1} onClick={() => onClickHandler(1)}>
          {1}
        </Pagination.Item>
      );
      if (active > boundaryCount + siblingCount + 2) {
        items?.push(<Pagination.Ellipsis key="ellipsis-1" />);
      }
      startPage = Math.max(2, startPage);
    }

    if (!rightBoundary) {
      endPage = Math.min(totalPages - 1, endPage);
    }

    for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
      items.push(
        <Pagination.Item key={pageNumber} active={pageNumber === active} onClick={() => onClickHandler(pageNumber)}>
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!rightBoundary) {
      if (active < totalPages - (boundaryCount + siblingCount)) {
        if (active < totalPages - (boundaryCount + siblingCount - 1)) {
          items?.push(<Pagination.Ellipsis key="ellipsis-2" />);
        }
        items?.push(
          <Pagination.Item key={totalPages} onClick={() => onClickHandler(totalPages)}>
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    return items;
  };

  return totalPages > 1 ? (
    <div className="d-flex justify-content-center">
      <Pagination>
        <Pagination.Prev onClick={() => onClickHandler(active - 1)} disabled={active === 1} />
        {renderPaginationItems()}
        <Pagination.Next onClick={() => onClickHandler(active + 1)} disabled={active === totalPages} />
      </Pagination>
    </div>
  ) : null;
};