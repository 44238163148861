import { useRef } from "react";
import { useSelector } from "react-redux";
import useIntersection from "../../../hooks/useIntersection";
import Post from "../../../services/post.service";

const PostTabs = ({ data }) => {
  const { profile } = useSelector((state) => state?.auth);
  const ref = useRef();
  const inViewport = useIntersection(ref);

  // let UserInInstituteOfPost = (profile?.id === data?.instituteid);
  let isNew;

  let tagInfo = data?.tags[0] && data?.tags?.find((user) => user?.userid === profile?.id);
  isNew = tagInfo ? tagInfo?.is_new : data?.is_new;

  if (inViewport) {
    console.log(">>>>>>>");
    Post.removeNewTag({ postid: data?.postid, userid: profile?.id, receiver_id: data?.receiver_id,sponsered:data?.sponsered,unique_id:data?.unique_id });
  }

  return (
    <div className="s-btn-group mb-3 mt-3">
      {data?.dmpost && <span className="btn-green s-btn me-2">DM</span>}
      {isNew && <span className="btn-green s-btn me-2" ref={ref}>NEW</span>}
      {data?.is_edited && <span className="btn-green s-btn me-2">EDITED</span>}
      {data?.is_cancelled && <span className="btn-red s-btn">CANCELLED</span>}
      {data?.sponsered && <span className="btn-green s-btn">SPONSORED</span>}
    </div>
  );
};

export default PostTabs;
