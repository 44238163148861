import { useState } from "react";
import Linkify from "react-linkify";

const PostDescription = ({ data }) => {
  let limitChar = 160;
  const [trimedDescription, setTrimedDescription] = useState(data?.description?.slice(0, limitChar) || "");
  const handleSeeMoreDescription = (desc) => {
    if (desc?.length <= limitChar) {
      setTrimedDescription(data?.description);
    } else {
      setTrimedDescription(desc?.slice(0, limitChar));
    }
  };
  const componentDecorator = (decoratedHref, decoratedText, key) => (
    <a target="blank" href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );
  return (
    <p className="mb-2" style={{ whiteSpace: "pre-line" }}>
      <Linkify componentDecorator={componentDecorator}>{trimedDescription?.trim()}</Linkify>
      {trimedDescription?.length >= limitChar ? (
        <button className="post-description ms-2" onClick={() => handleSeeMoreDescription(trimedDescription)}>
          {trimedDescription?.length <= limitChar ? "...see more" : "see less"}
        </button>
      ) : null}
    </p>
  );
};

export default PostDescription;
