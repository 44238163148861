import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AllPosts from "../../../components/post/AllPosts";
import { useDispatch, useSelector } from "react-redux";
import { getAllGroupPosts, getAllMemberOfSelectedGroup } from "../../../features/postSlice";
import SchoolAdmin from "../../../services/admin.service";

const GroupPosts = () => {
  const { state } = useLocation();

  console.log('====>', state);

  const [info, setInfo] = useState({});
  const { refresh } = useSelector((state) => state?.posts);
  const [data, setData] = useState({});
  const dispatch = useDispatch({ postCount: 0, memberCount: 0 });


  useEffect(() => {


    dispatch(getAllGroupPosts({ groupid: state?.id, search: "", current_page: 1 })).then((res) => setData((prev) => ({ ...prev, postCount: res?.payload?.total })));
    dispatch(getAllMemberOfSelectedGroup({ groupid: state?.id, search: "", current_page: 1, per_page : 999999 })).then((res) => setData((prev) => ({ ...prev, memberCount: res?.payload?.total })));

    SchoolAdmin.countUserData({ instituteid: state?.instituteid })
      .then((res) => setInfo(res?.data))
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [refresh]);
  return <AllPosts groupid={state?.id} info={info} instituteid={state?.instituteid} group_name={state?.group_name} count={data} showCrumbs={state?.showCrumbs} />;
};

export default GroupPosts;
