import Auth from "./routes/Auth";
import Admin from "./routes/Admin";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMe } from "./features/authSlice";
import { requestPermission, onMessageListener } from '../src/firebase';
import { Navigate, useRoutes } from "react-router";
import { useLocation } from 'react-router-dom';

import PrivacyMobile from "./pages/cms/PrivacyMobile";
import TermsConditionMobile from "./pages/cms/TermsConditionMobile";
import Tutorials from "./pages/cms/Tutorials";
import TutorialsMobile from "./pages/cms/TutorialsMobile";


function App() {
  const { token } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  // requestPermission();
  useEffect(() => {
    if (token) { 
      dispatch(getMe());
    }
    // eslint-disable-next-line
  }, [token]);


  let withoutHeaderElement = useRoutes([
    { path: "/mobile.privacypolicy", element: <PrivacyMobile /> },
    { path: "/mobile.termsandcondition", element: <TermsConditionMobile /> },
    { path: "/mobile.tutorials", element: <TutorialsMobile /> },
  ]);


  if (location?.pathname === '/mobile.privacypolicy' || location?.pathname === '/mobile.termsandcondition' || location?.pathname === '/mobile.tutorials') {
    return withoutHeaderElement;
  } else {
    return token ? <Admin /> : <Auth />;
  }




}

export default App;
