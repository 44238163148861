import { useEffect, useState } from "react";
import Finance from "../../services/finance.service";
import { Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { renewDateFormat } from "../../utils/DateFormat";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";

const customStyles = {
  rows: {
    style: { minHeight: "72px" },
  },
  headCells: {
    style: {
      overflow: "visible !important",
      whiteSpace: "normal !important",
      textOverflow: "initial",
      background: "#e9e9eb",
      fontSize: "15px",
      fontWeight: "500",
      color: "#000",
      border: "1px solid #e4e4e4",
      width: "132px",
    },
  },
  cells: {
    style: { border: "1px solid #e4e4e4", fontSize: "14px", color: "#000", fontWeight: "400", width: "132px", },
  },

};

const customSort = (rows, selector, direction) => {
  return rows?.sort((rowA, rowB) => {
    const aField = selector(rowA).toString().toLowerCase();
    const bField = selector(rowB).toString().toLowerCase();

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === "desc" ? comparison * -1 : comparison;
  });
};

const BillingSummary = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");
  const { adminInfo } = useSelector((state) => state?.auth);



  const post = {
    sponsered: "Sponsored Post",
    rsvp_and_pay: "Paid Event",
    pay: "Paid Event",
    renew: "Renewal",
    buy: "Merchandise Sale",
    merchandise: "Merchandise Sale",
  };

  useEffect(() => {
    Finance.viewBillingSummary()
      .then((res) => {
        let arr = res?.data?.data?.map((row) => ({
          Date: row?.created_at ? renewDateFormat(row?.created_at) : "NA",
          "Institute Name": row?.institutename,
          "Group Name": row?.groups?.length
            ? row.groups.map(group => `${group.group_name}${group.is_deleted ? ' (deleted)' : ''}`).filter(Boolean).join(", ")
            : row?.group_name?.length
              ? row.group_name.filter(Boolean).join(", ")
              : "NA",

          "Member Name": row?.user_name,
          "Child Name": row?.child_name ? row?.child_name : "NA",
          Type: post[row?.type] || row?.type?.replace(/_/g, " "),  // Use the mapping or replace underscores
          Description: row?.description?.length > 30 ? row.description.substring(0, 30) + '...' : row?.description,
          "Amount Paid": "$" + row?.amount?.toFixed(2),
          Confirmation: row?.confirmation,
          Notes: row?.comment ? row?.comment.substring(0, 30) + '...' : "NA",
          "Refund Amount": row?.refund_amount ? "-" + row?.refund_amount : "0",
        }));

        setData(arr);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);


  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA?.Date);
    const date2 = new Date(rowB?.Date);

    if (date2 > date1) {
      return 1;
    } else if (date2 < date1) {
      return -1;
    } else {
      return 0;
    }
  };

  let columns = [
    { name: "Date", sortable: true, sortFunction: sortingDate, selector: (row) => row?.Date },
    { name: "Institute Name", sortable: true, selector: (row) => row["Institute Name"] },
    { name: "Group Name", selector: (row) => row["Group Name"] },
    { name: "Member Name", minWidth: 200, sortable: true, selector: (row) => row["Member Name"] },
    { name: "Child Name", sortable: true, selector: (row) => row["Child Name"] },
    { name: "Type", sortable: true, selector: (row) => row?.Type },
    { name: "Post Title", sortable: true, selector: (row) => row?.Description },
    { name: "Amount Paid", selector: (row) => row["Amount Paid"] },
    { name: "Confirmation", sortable: true, selector: (row) => row?.Confirmation, cell: (row) => row?.Confirmation },
    // { name: "TransferId", sortable: false, selector: (row) => row?.TransferId, cell: (row) => row?.TransferId },
    { name: "Notes", sortable: true, selector: (row) => row?.Notes },
    { name: "Refund Amount", sortable: true, selector: (row) => row["Refund Amount"] },
  ];

  if (adminInfo?.role === 'admin') {
    columns = [
      { name: "Date", sortable: true, sortFunction: sortingDate, selector: (row) => row?.Date },
      { name: "Institute Name", sortable: true, selector: (row) => row["Institute Name"] },
      { name: "Group Name", selector: (row) => row["Group Name"] },
      { name: "Member Name", minWidth: 200, sortable: true, selector: (row) => row["Member Name"] },
      { name: "Child Name", sortable: true, selector: (row) => row["Child Name"] },
      { name: "Type", sortable: true, selector: (row) => row?.Type },
      { name: "Post Title", sortable: true, selector: (row) => row?.Description },
      { name: "Amount Paid", selector: (row) => row["Amount Paid"] },
      { name: "Confirmation", sortable: true, selector: (row) => row?.Confirmation, cell: (row) => row?.Confirmation },
      // { name: "TransferId", sortable: false, selector: (row) => row?.TransferId, cell: (row) => row?.TransferId },
      { name: "Notes", sortable: true, selector: (row) => row?.Notes },
      { name: "Refund Amount", sortable: true, selector: (row) => row["Refund Amount"] },
    ];
  }


  const dataToRender = () => {
    let arr = [...data];

    if (filter === "all") return arr;

    if (filter === "paid") {
      return arr.filter((item) => ["Paid Event"].includes(item?.Type));
    }

    if (filter === "merchandise") {
      return arr.filter((item) => item?.Type === "Merchandise Sale");
    }

    return arr.filter((item) => item?.Type === post[filter]);
  };

  const fxSheet = () => {
    let arr = dataToRender();

    let header = ["Date", "Institute Name", "Group Name", "Member Name", "Child Name", "Type", "Post Title", "Amount Paid", "Confirmation", "Notes", "Refund Amount"];

    if (adminInfo?.role === 'admin') {
      header = ["Date", "Institute Name", "Group Name", "Member Name", "Child Name", "Type", "Post Title", "Amount Paid", "Confirmation", "Notes", "Refund Amount"];
    }

    const compatibleData = arr?.map((row) => {
      const obj = {};
      header?.forEach((col) => {
        if (col === "Type") {
          obj[col] = post[row?.Type] || row?.Type?.replace(/_/g, " ");
        } else if (col === "Amount Paid") {
          obj[col] = parseFloat(row[col].replace(/[$,]/g, ""));
        } else if (col === "Refund Amount") {
          obj[col] = parseFloat(row[col].replace(/[-,]/g, ""));
        } else {
          obj[col] = row[col];
        }
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
    XLSX.utils.book_append_sheet(wb, ws1, "Billing Summary");
    XLSX.writeFile(wb, `Billing_Summary.xlsx`);
  };


  return (
    <div className="wraper-inner bg-grey  space-top">
      <section className="billing-summary pt-4 pb-5 min-box">
        <div className="container">
          <div className="similar-shadow-box pb-5">
            <div className="head-btn d-flex justify-content-between">
              <h3 className="inner-title mb-4">Billing Summary </h3>
              <button className="btn black-btn common-btn" onClick={fxSheet}>
                Export to Excel
              </button>
            </div>
            {loading ? (
              <Spinner animation="border" variant="success" />
            ) : (
              <>
                <ul className="nav nav-tabs similer-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "all" && "active"}`} onClick={() => setFilter("all")}>
                      Summary
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "renew" && "active"}`} onClick={() => setFilter("renew")}>
                      Renewals
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "paid" && "active"}`} onClick={() => setFilter("paid")}>
                      Paid Events
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${filter === "merchandise" && "active"}`} onClick={() => setFilter("merchandise")}>
                      Merchandise Sale
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active">
                    <div className="table-box">
                      <DataTable customStyles={customStyles} data={dataToRender()} columns={columns} defaultSortFieldId={1} sortFunction={customSort} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BillingSummary;
