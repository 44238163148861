import { Link } from "react-router-dom";
import CalendarPost from "./CalendarPost";
import MerchandisePost from "./MerchandisePost";
import RenewPost from "./RenewPost";
import StandardPost from "./StandardPost";
import AdPost from "./AdPost";
import ListGroup from "../../pages/schooladmin/groups/ListGroup";
import ListMember from "../../pages/schooladmin/members/ListMember";
import { useDispatch, useSelector } from "react-redux";
import { PaginationBlock } from "../PaginateBlock";
import { Spinner } from "react-bootstrap";
import { roleCheck } from "../../utils/RoleCheck";
import SearchBar from "../SearchBar";
import { PermissionCheck } from "../../utils/Permissions";
import Events from "./postEvents";
import BoxW from "../wrapper/BoxW";
import BreadCrumbs from "../misc/BreadCrumbs";
import { open } from "../../features/eventSlice";

const AllPosts = ({ home, info, groupid, group_name, instituteid, count, showCrumbs, dm }) => {
  const { posts: allposts, loading } = useSelector((state) => state?.posts);
  const auth = useSelector((state) => state?.auth);
  const checkNotAMember = roleCheck(["admin", "teacher", "parent"], auth?.logintype);

  const dispatch = useDispatch();


  const handleBulk = () => {
    let event = 'bulk';
    let data = { groupid: groupid };
    let member = 'member';
    dispatch(open({ event, data, member }));
  }


  const content = () => {
    let message = auth?.firstTime ? "Welcome! No posts at this time." : "There are no records to display.";
    return (
      <>
      
        {allposts?.length
          ? allposts?.map((content, i) => {
            if (content.standard_event) {
              return <StandardPost key={i} data={content} />;
            } else if (content.calendar_event) {
              return <CalendarPost key={i} data={content} />;
            } else if (content.merchandise_sale) {
              return <MerchandisePost key={i} data={content} />;
            } else if (content.renewal) {
              return <RenewPost key={i} data={content} />;
            } else if (content.sponsered) {
              return <AdPost key={i} data={content} />
            } else return null;
          })
          : message}
        <PaginationBlock />
      </>
    );
  };

  if (dm) {
    return (
      <div className="row all-post-group">
        <div className="col-md-12">{loading ? <Spinner animation="border" variant="success" /> : content()}</div>
        <Events />
      </div>
    );
  }

  return (
    <>
      <div className="wraper-inner bg-grey space-top">
        <section className="home-main pt-4 pb-5 min-box">
          <div className="container">
            <PermissionCheck instituteid={instituteid}>
              {showCrumbs && <BreadCrumbs group_name={group_name} />}
              <div className="home-head similar-shadow-2">
                <PermissionCheck instituteid={instituteid} checkAdmin={home ? true : false}>
                  <small className="d-flex justify-content-center align-items-center mb-3 text-capitalize">
                    {home ? <BoxW title={true} icon="ti ti-building" text={info?.institutename} /> : <BoxW title={true} icon="ti ti-users" text={group_name} />}
                  </small>
                  <PermissionCheck instituteid={instituteid}>
                    <ul className="total-box d-flex justify-content-center mb-3 ps-0">
                      {home ? <BoxW text={"Total Members"} info={info?.users} /> : <BoxW text={"Total Posts"} info={count?.postCount} />}
                      {home ? <BoxW text={"Total Groups"} info={info?.groups} /> : <BoxW text={"Total Members"} info={count?.memberCount} />}
                    </ul>
                  </PermissionCheck>
                </PermissionCheck>
                <div className="home-btn-group d-flex justify-content-center">
                  <PermissionCheck instituteid={instituteid}>
                    <Link to="/create-post" state={groupid ? { groupid, instituteid } : {}} className="blue-btn hvr-sweep-to-right">
                      <i className="ti ti-message"></i>Create Post
                    </Link>
                  </PermissionCheck>
                  <PermissionCheck instituteid={instituteid} checkAdmin={true}>
                    {/* <Link to="/add-members" state={groupid ? { groupid, role: "member" } : { role: "member" }} className="blue-border-btn">
                      <i className="ti ti-user-plus"></i>Add Member
                    </Link> */}

                    {/* <Link to="#" onClick={handleBulk} className="blue-border-btn">
                      <i className="ti ti-user-plus"></i>Add Member
                    </Link> */}

                    <button className="blue-border-btn" onClick={handleBulk}>
                      <i className="ti ti-user-plus"></i>Add Member
                    </button>


                  </PermissionCheck>
                  {home && (
                    <PermissionCheck instituteid={instituteid} checkAdmin={true}>
                      <Link to="/create-groups" className="black-btn">
                        <i className="ti ti-users"></i>Create Group
                      </Link>
                    </PermissionCheck>
                  )}
                </div>
              </div>
            </PermissionCheck>
            <SearchBar />
            <hr className="border-grey mt-4 mb-4" />
            <div className="row all-post-group">
              {checkNotAMember && <div className="col-md-4 all-group-L">{home ? <ListGroup small={true} /> : <ListMember small={true} groupid={groupid} instituteid={instituteid} />}</div>}
              <div className={checkNotAMember ? "col-md-8 all-posts" : "col-md-12"}>
                {/* <h3 id="top" className="inner-title mb-3">All Posts</h3> */}
                <h3 className="inner-title mb-3">All Posts</h3>
                {loading ? <Spinner animation="border" variant="success" /> : content()}
              </div>
            </div>
          </div>
        </section>
        <Events />
      </div>
    </>
  );
};

export default AllPosts;
