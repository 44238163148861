import { useEffect, useState } from "react";
import homeIcon from "../../assets/images/home-icon.png";
import CardW from "../../components/wrapper/CardW";
import Swal from "sweetalert2";
import { Delete, Error, Note } from "../../utils/Alert";
import { useNavigate } from "react-router-dom";
import Finance from "../../services/finance.service";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const List = () => {
  const { adminInfo } = useSelector((state) => state?.auth);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    Finance.List({ type: "view", ...adminInfo })
      .then((res) => setData(res?.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const fxDelete = () => {



    Swal.fire({ title: "Are you sure you want to delete the financial information?", ...Delete }).then((result) => {
      if (result.isConfirmed) {


        setLoading(true);
        Finance.List({ type: "delete", ...adminInfo })
          .then((res) => setData(res?.data?.data))
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      }
    });
  };

  const handleAddInfo = async () => {
    Finance.List({ type: "add", instituteid: adminInfo?.instituteid })
      .then((res) => {
        const { url } = res?.data;
        window.open(url, '_self');
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
      });
  };

  console.log(data)

  let content;
  if (data && data?.data && (data?.data?.data[0]?.object === "bank_account" || data?.data?.data[0]?.object === "card")) {
    content = (
      <>
        <p>Financial information will be used for crediting all payment transactions in the system.</p>
        <ul className="bank-info-list mt-3">
          <li className="similar-shadow-3">
            <div className="d-flex justify-content-between">
              <div className="bank-info-list-L">
                <h4>
                  <i className="ti ti-building-bank" />
                  {data?.data?.data[0]?.object === "bank_account" ? "Bank Account" : "Debit Card"}
                  {data?.verifed ? <i className="ti ti-circle-check ml-1"></i> : " (Not verified)"}
                </h4>
                <span>************{data?.data?.data[0]?.last4}</span>
              </div>
              <div className="bank-info-list-R d-flex">
                <button className="delete-post ms-2" onClick={() => fxDelete()}>
                  <i className="ti ti-trash" />
                </button>
              </div>
            </div>
            {data?.data?.data[0]?.object === "card" ? (
              <div className="card-info-box d-flex mt-3">
                {/* <div className="card-info-box-content">
                  <b>Cardholder Name</b>
                  <span>{data.data?.data[0]?.name}</span>
                </div> */}
                <div className="card-info-box-content">
                  <b>Valid Through</b>
                  {data?.data?.data[0]?.exp_month?.toString().padStart(2, "0") + "/" + data?.data?.data[0]?.exp_year?.toString().padStart(2, "0")}
                </div>
              </div>
            ) : null}
          </li>
        </ul>
      </>
    );
  } else {
    content = (
      <div className="text-center col-md-6 m-auto no-financial mt-5 pt-5 mb-5 ">
        <figure>
          <img src={homeIcon} alt="bank" />
        </figure>
        <h4>No Financial Information Added</h4>
        <p className="mb-2">Financial information will be used for crediting all payment transactions in the system. Notetify uses Stripe to handle payments across our platform. Stripe is an industry-leading payment service provider, used by businesses like Lyft, Kickstarter, and Shopify.</p>
        <p className="mb-2">All organizations must connect a bank account through Stripe in order to get paid.</p>  
        <p className="mb-2">Clicking the button below will take you to Stripe's Onboarding page.</p>  
        <p className="mb-1">If you already have a Stripe account, you do no need to go through the setup process again. You can click the button below to enable payouts by connecting your existing Stripe account to Notetify.</p>
            
        <div className="mt-4 btn-box">
          <button className="btn big-btn hvr-sweep-to-right" onClick={handleAddInfo}>
            Add Financial Information
          </button>
        </div>
      </div>
    );
  }

  return (
    <CardW>
      <h3 className="inner-title mb-4">Manage Financial Information</h3>
      {loading ? <Spinner animation="border" variant="success" /> : content}
    </CardW>
  );
};

export default List;