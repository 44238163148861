import { useState } from "react";
import { useDispatch } from "react-redux";
import { open } from "../../features/eventSlice";
import { likeDislikePost } from "../../features/postSlice";
import { PostComments, PostDescription, PostGroups, PostInteraction, PostMedia, PostOps, PostTabs, PostTitle } from "./postBlocks";
import { PostPermission } from "../../utils/Permissions";

const StandardPost = ({ data }) => {
  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false);
  let { showEdit, showDelete } = PostPermission("standard", data);
  const [likebuttonclicked, setLikeClicked] = useState(false);


  const handleLike = () => {
    if (!likebuttonclicked) {
      setLikeClicked(true);



      dispatch(likeDislikePost({ postid: data?.id, title: data?.title, likeflag: data?.likeflag ? true : false }))
        .finally(() => setLikeClicked(false));
    }
  };

  const handleEvent = (event) => dispatch(open({ event,id: data?.id, data }));


  return (
    <div className="all-posts-box similar-shadow-2 mb-3 border-box">
      <PostOps data={data} showEdit={showEdit} showDelete={showDelete} />
      <PostTitle data={data} />
      <PostGroups data={data} />
      <PostTabs data={data} />
      <PostDescription data={data} />
      <PostMedia data={data} />
      <PostInteraction data={data}>
        <ul className="d-flex">
          <li className={data?.likeflag ? "blue-thumb" : ""} onClick={handleLike}>
            <button>
              <i className="ti ti-thumb-up"></i> {data?.like_count}
            </button>
          </li>
          {!data?.disable_comments && (
            <li>
              <button onClick={() => setShowComment(!showComment)}>
                <i className="ti ti-message-dots"></i> {data?.comment_count}
              </button>
            </li>
          )}
        </ul>
        <div className="d-flex align-items-center bell-noti-main">
          {!data?.dmpost &&
            <button onClick={() => handleEvent("remindMe")} className={data?.tags[0]?.remind_me ? "bell-noti-blue ms-2" : "bell-noti ms-2"} >
              <i className="ti ti-bell-ringing"></i>
            </button>
          }
        </div>
      </PostInteraction>
      {!data?.disable_comments && showComment && <PostComments data={data} />}
    </div>
  );
};

export default StandardPost;
