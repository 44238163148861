import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SchoolAdmin from "../../services/admin.service";
import { Delete, Error, Info, Note } from "../../utils/Alert";
import AddTeacher from "./tabs/AddTeacher";

const ManageTeacher = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    SchoolAdmin.viewMemberByRoles({ role: "teacher" })
      .then((res) => setData(res.data.members))
      .catch((err) => {
        const { message } = err.response?.data;
        Swal.fire({ text: message ? message : Note.Err, ...Error });
      });
  }, [refresh]);

  const handleDelete = (row) => {
    const { userid, instituteid } = row;
    let title = "Are you sure you want to delete the Teacher/Group Leader?";
    Swal.fire({ title, ...Delete }).then((result) => {
      if (result.isConfirmed) {
        let grpid = row?.grps?.map((grp) => grp?.group_id);
        SchoolAdmin.deleteMember({ userid, instituteid, role: "teacher", grpid })
          .then(() => {
            setRefresh(!refresh);
            Swal.fire({ text: Note.TeacherDeleted, ...Info });
          })
          .catch((err) => {
            const { message } = err?.response?.data;
            Swal.fire({ text: message ? message : Note.Err, ...Error });
          });
      }
    });
  };
  return (
    <>
      <div className="tab-pane fade show active" id="tabs-5" role="tabpanel" aria-labelledby="tabbing-5">
        <div className="pb-5">
          <div className="d-flex justify-content-between">
            <h3 className="inner-title mb-4">Manage Teacher/ Group Leaders</h3>
            <Link to="/add-members" state={{ role: "teacher" }} className="blue-link">
              Add New Teacher/ Group Leader
            </Link>
          </div>
          <ul className="manage-admin-list">
            {data?.length ? (
              data?.map((user, index) => (
                <li className="manage-admin-list-box similar-shadow-3 d-flex justify-content-between" key={index}>
                  <div className="manage-admin-list-L">
                    <h4>
                      {user?.firstname} {user?.lastname}
                    </h4>
                    <div className="contact-info d-flex">
                      <Link to="#">
                        <i className="ti ti-mail"></i> {user?.email}
                      </Link>
                      <Link to="#">
                        <i className="ti ti-phone"></i> {user?.phoneno}
                      </Link>
                      {/* <Link to="#"><i className="ti ti-users"></i> {user.grps.length} Groups</Link> */}
                    </div>
                  </div>
                  <div className="manage-admin-list-R d-flex">
                    <Link to="/edit-member-profile" state={{ id: user?.userid, memberid: user?.memberid }} className="edit-post ms-3">
                      <i className="ti ti-pencil"></i>
                    </Link>
                    <Link to="#" className="delete-post ms-2" onClick={() => handleDelete(user)}>
                      <i className="ti ti-trash"></i>
                    </Link>
                  </div>
                </li>
              ))
            ) : (
              <div className="text-center">There are no records to display</div>
            )}
          </ul>
        </div>
      </div>
      {/* <!-- Add Teacher/ Group Leader--> */}
      <AddTeacher refresh={refresh} setRefresh={setRefresh} />
    </>
  );
};

export default ManageTeacher;
