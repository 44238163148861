import React, { useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addCommentToPost, deleteCommentInPost, editCommentInPost } from "../../../features/postSlice";
import postUser from "../../../assets/images/DefaultUserIcon.png";
import DateFormat from "../../../utils/DateFormat";
import { BASE, NOSPACE_REGEX } from "../../../utils/Constants";
import Swal from "sweetalert2";
import { Delete, Info, Note } from "../../../utils/Alert";
import { MESSAGE } from "../../../utils/ValidationMessage";

const PostComments = ({ data }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState([]);
  const { profile } = useSelector((state) => state?.auth);

  const user_role= profile?.roledata?.find((item) => item?.instituteid === data?.instituteid)?.role;
  
  const initialValues = { comment: "", commentid: "", userid: profile?.id, sys_date: new Date() };
  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
  });
  const onAddSubmit = (values, { resetForm }) => {
    dispatch(addCommentToPost({ postid: data?.id, ...values }));
    resetForm();
  };
  const onEditSubmit = (values, { resetForm }) => {
    dispatch(editCommentInPost({ postid: data?.id, ...values }));
    setOpen([]);
    resetForm();
  };
  const deleteComment = (commentid) => {
    setOpen([]);
    Swal.fire({ title: "Are you sure you want to delete the comment?", ...Delete }).then((result) => {
      if (result?.isConfirmed) {
        dispatch(deleteCommentInPost({ postid: data?.postid, commentid }));
        Swal.fire({ text: Note.CommentDeleted, ...Info });
      }
    });
  };

  const commentingUser = (arr1, commentedUser, type) => {
    let user = arr1.filter((user) => commentedUser?.userid === user?.id);
    let commentedUserInfo = data?.commenters_member?.find((x) => x?.userid === user[0]?.id);
    let status = commentedUserInfo?.is_deleted ? " (deleted)" : "";
    if (type === "name") return user[0]?.fullname + status;
    else return user[0]?.profilepic ? BASE + "/" + user[0]?.profilepic : postUser;
  };

  const onOpenHandler = (val, i) => {
    const arr = [...open];
    arr[i] = val;
    setOpen(arr);
  };

  return (
    <div className="comments-post">
      <h4 className="sub-title mb-2 mt-2">Comments</h4>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onAddSubmit}>
        {({ handleSubmit }) => (
          <>
            <div className="type-comment mb-2">
              <form onSubmit={handleSubmit}>
                <Field type="text" name="comment" placeholder="Post your comment here...." className="form-control" />
                <button className="post-comment-btn">
                  <i className="ti ti-send"></i>
                </button>
              </form>
            </div>
          </>
        )}
      </Formik>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onEditSubmit}>
        {({ handleSubmit, setFieldValue, values }) => (
          <ul className="post-comment-list">
            {data?.total_comment[0] &&
              data?.total_comment
                ?.slice()
                .sort((a, b) => new Date(b?.updated_at) - new Date(a?.updated_at))
                .map((comment, i) => (
                  <React.Fragment key={i}>
                    <li className="d-flex">
                      <figure className="post-comment-list-L">
                        <img 
                            src={commentingUser(data?.commenters, comment)} 
                            alt="icon" 
                            onError={(e) => {
                              e.target.src = postUser; 
                            }}
                        />
                      </figure>
                      <figcaption className="post-comment-list-R">
                        <div className="post-comment-list-R-head d-flex justify-content-between mb-1">
                          <h3>{commentingUser(data?.commenters, comment, "name")}</h3>
                          <div className="post-comment-list-R-head-right d-flex align-items-center">
                            <small>{DateFormat(comment?.updated_at, true)}</small>
                            {comment?.userid === profile?.id && (
                              <>
                                <button
                                  type="button"
                                  className="edit-post ms-3"
                                  onClick={() => {
                                    onOpenHandler(true, i);
                                    setFieldValue("comment", comment?.comment);
                                    setFieldValue("commentid", comment?.id);
                                  }}
                                >
                                  <i className="ti ti-pencil"></i>
                                </button>
                              
                              </>
                           )} 


                          {(comment?.userid === profile?.id || user_role=='admin' ) &&  (    


                          <button type="button" className="delete-post ms-2" onClick={() => deleteComment(comment?.id)}>
                                                            <i className="ti ti-trash"></i>
                          </button>
                                                          
                          )} 




                          </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                          {open[i] ? (
                            <div className="post-input">
                              <Field type="text" name="comment" className="form-control" />
                              <div className="form-action">
                                <button type="button" className="crossBtn" onClick={() => onOpenHandler(false, i)}>
                                  <i className="ti ti-x"></i>
                                </button>
                                <button type="submit" className="sendBtn" disabled={comment?.comment === values?.comment}>
                                  <i className="ti ti-send"></i>
                                </button>
                              </div>
                            </div>
                          ) : (
                            <p>{comment?.comment}</p>
                          )}
                        </form>
                      </figcaption>
                    </li>
                  </React.Fragment>
                ))}
          </ul>
        )}
      </Formik>
    </div>
  );
};

export default PostComments;
