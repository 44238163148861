import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { open } from "../../features/eventSlice";
import { likeDislikePost } from "../../features/postSlice";
import DateFormat from "../../utils/DateFormat";
import { PostPermission } from "../../utils/Permissions";
import { PostComments, PostDescription, PostGroups, PostMedia, PostOps, PostTabs, PostTitle } from "./postBlocks";
import Swal from "sweetalert2";
import { Info, Error, Note, Success } from "../../utils/Alert";

const RenewPost = ({ data }) => {



  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false);
  const { profile } = useSelector((state) => state?.auth);
  let { showEdit, showDelete } = PostPermission("", data);
  const [likebuttonclicked, setLikeClicked] = useState(false);


  const handleLike = () => {
    if (!likebuttonclicked) {
      setLikeClicked(true);
      dispatch(likeDislikePost({ postid: data?.id, title: data?.title, likeflag: data?.likeflag ? true : false }))
        .finally(() => setLikeClicked(false));
    }
  };


  const handleRemindEvent = (event) => dispatch(open({ event,id:data?.id, data }));

  const handleEvent = (event) => {

    let member = profile?.roledata?.find((item) => item?.instituteid === data?.instituteid);
    let login_user_id = profile?.id;
    let is_login_user_already_paid = data?.eventusers?.filter(Boolean).find((x) => x?.id === login_user_id);
    let login_user_response_on_post = data?.postevent?.filter(Boolean).find((x) => x?.memberid === member?.memberid);


    if (member?.membership_status == false) {
      Swal.fire({ text: "Sorry..Deactivated member not allowed." ?? Note?.Err, ...Error });
      return false;
    }


    let message = null;
    if (member?.stripe_account_id == null || member?.stripe_account_id == "") {
      message =
        member?.role === "admin"
          ? "Financial transactions are not enabled. Please add your bank or debit card information."
          : "Financial transactions are not enabled. Please contact your Administrator.";
    }



    if (message != null) {
      Swal.fire({ text: message ?? Note?.Err, ...Error });
      return false;
    }


    if (event == 'renew' && is_login_user_already_paid) {
      Swal.fire({ text: "Payment has already been made. Multiple payments are not allowed." ?? Note?.Err, ...Error });
      return false;
    }




    dispatch(open({ event, data, member }));




  };
  return (
    <div className="all-posts-box similar-shadow-2 mb-3 border-box">
      <PostOps data={data} showEdit={showEdit} showDelete={showDelete} />
      <PostTitle data={data} />
      <PostGroups data={data} />
      <PostTabs data={data} />
      <div className="payment-info d-flex mt-3 mb-2">
        <span className="me-4">
          <b>Payment Amount: </b>${data?.price}
        </span>
        <span>
          <b>Due : </b>
          {DateFormat(data?.due_date, true).toString().slice(0, 10)}
        </span>
      </div>
      <PostDescription data={data} />
      <PostMedia data={data} />
      <div className="all-posts-footer d-flex justify-content-between">
        <ul className="d-flex">
          <li className={data?.likeflag ? "blue-thumb" : ""} onClick={handleLike}>
            <button>
              <i className="ti ti-thumb-up"></i> {data?.like_count}
            </button>
          </li>
          {!data?.disable_comments && (
            <li>
              <button onClick={() => setShowComment(!showComment)}>
                <i className="ti ti-message-dots"></i> {data?.comment_count}
              </button>
            </li>
          )}
        </ul>
        <div className="d-flex align-items-center bell-noti-main">
          <button onClick={() => handleEvent("renew")}>RENEW</button>
          {!data?.dmpost &&
            <button onClick={() => handleRemindEvent("remindMe")} className={data?.tags[0]?.remind_me ? "bell-noti-blue ms-2" : "bell-noti ms-2"} >
              <i className="ti ti-bell-ringing"></i>
            </button>
          }
        </div>
      </div>
      {!data?.disable_comments && showComment && <PostComments data={data} />}
    </div>
  );
};

export default RenewPost;
