import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../features/eventSlice";
import { read, utils, writeFile } from 'xlsx';
import { registrationDetails } from "../../utils/RegistrationDetails";
import { Delete, Error, Info, Note, Success } from "../../utils/Alert";
import SchoolAdmin from "../../services/admin.service";
import Swal from "sweetalert2";
import Loading from "../skeleton/Loading";



const AddBulk = () => {
  const { bulk, data } = useSelector((state) => state?.event);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(close("bulk"));
  const { adminInfo } = useSelector((state) => state?.auth);
  const [groupData, setGroupData] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [FileStatus, setFileStatus] = useState(0);  // 0=> Nothing | 1=>Verifying | 2=> completed Verification | 3=> Uploading | 4=> Complete uploading  
  const [fileName, setFileName] = useState('Bulk Upload');
  const instituteid = adminInfo?.instituteid;
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [loadingscreen , setLoadingScreen] = useState(false);



  useEffect(() => {

    SchoolAdmin.viewGroupDropDown({ allInstitutesIds: [adminInfo?.instituteid] })
      .then((res) => {
        setGroupData(res?.data?.data);
        let groupList = res?.data?.data
          .filter((group) => group?.instituteid === adminInfo?.instituteid)
          .map((element) => element?.group_name);
        setGroupNames(groupList);


      })
      .catch((err) => console.log(err));
  }, []);


  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function areAllElementsIncluded(arr1, arr2) {
    const lowerArr1 = arr1?.map(element => element.toLowerCase());
    const lowerArr2 = arr2?.map(element => element.toLowerCase());

    // console.log('000---->>',lowerArr1.every(element => lowerArr2.includes(element)));

    return lowerArr1.every(element => lowerArr2.includes(element));
  }


  const handleImport = ($event) => {
    const files = $event?.target?.files;
    if (files?.length) {
      const file = files[0];
      setFileName(file?.name);


      const reader = new FileReader();
      reader.onload = (event) => {

        const wb = read(event?.target?.result);
        const sheets = wb?.SheetNames;

        if (sheets?.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let unique_email_array = [];
          let error = 0;
          let message = '';
          let uniqueGroupId = [];

          for (const [key, item] of rows.entries()) {
          
            item.ChildExits = 0;
            item.RegistrationDetailExist1 = 0;
            item.RegistrationDetailExist2 = 0;
            item.RegistrationExits = 0;
            if(!item.Notes?.trim()){
              item.Notes = "";
            }
            if (item?.ChildName1) {
                item.ChildExits = 1;
              
                if (item.RegistrationRenewalAmount || item.RegistrationFrequency_MonthlyORYearly || item.RegistrationRenewalMonth || item.RegistrationRenewalDayofMonth || item.Notes) {
                    item.RegistrationDetailExist1 = 1;
                }
            }
            if (item?.RegistrationRenewalAmount || item?.RegistrationFrequency_MonthlyORYearly || item?.RegistrationRenewalMonth || item?.RegistrationRenewalDayofMonth || item?.Notes){
              if (!item?.ChildName1){
                error = 1;
                message = `Error at row ${key + 1}. Missing ChildName1.`;
                break;
              }
              if(item.RegistrationRenewalDayofMonth < 1 || item.RegistrationRenewalDayofMonth >28){
                error = 1;
                message = `Error at row ${key + 1}: RegistrationRenewalDay between 1 to 28.`;
                break;
              }
              if(item.RegistrationRenewalMonth < 1 || item.RegistrationRenewalMonth >12){
                error = 1;
                message = `Error at row ${key + 1}: RegistrationRenewalDay between 1 to 12.`;
                break;
              }
            }
            if(!item.Notes2?.trim()){
              item.Notes2 = "";
            }
            if (item.ChildName2) {
                item.ChildExits = 1;
                if (item.RegistrationRenewalAmount2 || item.RegistrationFrequency_MonthlyORYearly2 || item.RegistrationRenewalMonth2 || item.RegistrationRenewalDayofMonth2 || item.Notes2) {
                    item.RegistrationDetailExist2 = 1;
                }
            }
            if (item.RegistrationRenewalAmount2 || item.RegistrationFrequency_MonthlyORYearly2 || item.RegistrationRenewalMonth2 || item.RegistrationRenewalDayofMonth2 || item.Notes2){
              if (!item.ChildName2){
                error = 1;
                message = `Error at row ${key + 1}. Missing ChildName2.`;
                break;
              }
              if(item.RegistrationRenewalDayofMonth2 < 1 || item.RegistrationRenewalDayofMonth2 >28){
                error = 1;
                message = `Error at row ${key + 1}: RegistrationRenewalDay between 1 to 28.`;
                break;
              }
              if(item.RegistrationRenewalMonth2 < 1 || item.RegistrationRenewalMonth2 >12){
                error = 1;
                message = `Error at row ${key + 1}: RegistrationRenewalDay between 1 to 12.`;
                break;
              }
            }
            if(!item.MemberRenewalNote?.trim()){
              item.MemberRenewalNote = "";
            }
        
            if (item.MemberRenewalAmount || item.MemberRenewalPeriod || item.MemberRenewalNote || item.MemberRenewalMonth || item.MemberRenewalDay) {
              if(item.MemberRenewalDay < 1 || item.MemberRenewalDay >28){
                error = 1;
                message = `Error at row ${key + 1}: MemberRenewalDay between 1 to 28.`;
                break;
              }
              if(item.MemberRenewalMonth < 1 || item.MemberRenewalMonth >12){
                error = 1;
                message = `Error at row ${key + 1}: RegistrationRenewalDay between 1 to 12.`;
                break;
              }
                item.RegistrationExits = 1;
            }
        
            if (item.ChildExits && item.RegistrationExits) {
                error = 1;
                message = `Error at row ${key + 1}: Please remove child or member registration detail. Both are not applicable.`;
                break;
            }
        
            if (item.MemberFirstName === undefined) {
                error = 1;
                message = `Error at row ${key + 1}. Missing first name.`;
                break;
            }
            if (item.MemberLastName === undefined) {
                error = 1;
                message = `Error at row ${key + 1}. Missing last name.`;
                break;
            }
        
            if (!isValidEmail(item.MemberEmailAddress)) {
                error = 1;
                message = `Error at row ${key + 1}. Missing email address.`;
                break;
            }
            if (item.MemberMobileNumber === undefined) {
                error = 1;
                message = `Error at row ${key + 1}. Missing phone number.`;
                break;
            }
            if (item.MemberZipCode === undefined) {
                error = 1;
                message = `Error at row ${key + 1}. Missing zip code.`;
                break;
            }
        
            if (unique_email_array.includes(item.MemberEmailAddress)) {
                error = 1;
                message = `Error at row ${key + 1}. Email ${item.MemberEmailAddress} already exists. Please provide a unique Email Address.`;
                break;
            }
            unique_email_array.push(item.MemberEmailAddress);
        
            if (item.MemberGroup) {
              let sheetGroupArray = String(item.MemberGroup).split(",").map(group => group.trim());
              
              if (!areAllElementsIncluded(sheetGroupArray, groupNames)) {
                  error = 1;
                  message = `Error at row ${key + 1}: Group name does not exist.`;
                  break;
              }
          
              uniqueGroupId = groupData
                  .filter((group) => group?.instituteid === adminInfo?.instituteid && sheetGroupArray.some(name => name.toLowerCase() === group?.group_name.toLowerCase()))
                  .map((element) => element?.group_id);
              
              item['grp_id'] = uniqueGroupId;
          } else {
              error = 1;
              message = `Error at row ${key + 1}. Missing member group.`;
              break;
          }
          
        
            if (item?.RegistrationExits) {
                const requiredFields = [
                    { field: 'MemberRenewalAmount', message: 'Missing renewal amount.' },
                    { field: 'MemberRenewalPeriod', message: 'Missing renewal period.' },
                    { field: 'MemberRenewalMonth', message: 'Missing renewal month.' },
                    { field: 'MemberRenewalDay', message: 'Missing renewal date.' },
                    { field: 'MemberRenewalNote', message: 'Missing renewal note.' }
                ];
        
                for (const { field, message: errorMessage } of requiredFields) {
                    if (!item[field]) {
                        error = 1;
                        message = `Error at row ${key + 1}. ${errorMessage}`;
                        break;
                    }
                }
            }
        
            if (item.RegistrationDetailExist1) {
                const requiredFields = [
                    { field: 'RegistrationRenewalAmount', message: 'Missing registration renewal amount.' },
                    { field: 'RegistrationFrequency_MonthlyORYearly', message: 'Missing registration frequency.' },
                    { field: 'RegistrationRenewalMonth', message: 'Missing registration renewal month.' },
                    { field: 'RegistrationRenewalDayofMonth', message: 'Missing registration renewal day.' },
                    { field: 'Notes', message: 'Missing registration renewal note.' }
                ];
        
                for (const { field, message: errorMessage } of requiredFields) {
                  if (!item[field]) {
                      error = 1;
                      message = `Error at row ${key + 1}. ${errorMessage}`;
                      break;
                  }
              }
            }
        
            if (item.RegistrationDetailExist2) {
                const requiredFields = [
                    { field: 'RegistrationRenewalAmount2', message: 'Missing registration renewal amount 2.' },
                    { field: 'RegistrationFrequency_MonthlyORYearly2', message: 'Missing registration frequency 2.' },
                    { field: 'RegistrationRenewalMonth2', message: 'Missing registration renewal month 2.' },
                    { field: 'RegistrationRenewalDayofMonth2', message: 'Missing registration renewal day 2.' },
                    { field: 'Notes2', message: 'Missing registration renewal note 2.' }
                ];
        
                for (const { field, message: errorMessage } of requiredFields) {
                  if (!item[field]) {
                      error = 1;
                      message = `Error at row ${key + 1}. ${errorMessage}`;
                      break;
                  }
              }
            }
        }
        



          if (error) {
            fileInputRef.current.value = null;
            setFileName('Bulk Upload');
            Swal.fire({ text: message ? message : Note.Err, ...Error, timer: 1000000 });
            return false;
          }



          let File_data = [];

          rows.forEach((item) => {
            let child_array = [];

            item.ChildExits = 0;
            item.RegistrationDetailExist1 = 0;
            item.RegistrationDetailExist2 = 0;
            item.RegistrationExits = 0;
            if(!item.Notes?.trim()){
              item.Notes = "";
            }
            if (item.ChildName1) {
              item.ChildExits = 1;
              if (item.RegistrationRenewalAmount && item.RegistrationFrequency_MonthlyORYearly && item.RegistrationRenewalMonth && item.RegistrationRenewalDayofMonth && item.Notes) {
                item.RegistrationDetailExist1 = 1;
              }

            }
            if(!item.Notes2?.trim()){
              item.Notes2 = "";
            }
            if (item.ChildName2) {
              item.ChildExits = 1;
              if (item.RegistrationRenewalAmount2 && item.RegistrationFrequency_MonthlyORYearly2 && item.RegistrationRenewalMonth2 && item.RegistrationRenewalDayofMonth2 && item.Notes2) {
                item.RegistrationDetailExist2 = 1;
              }
            }
            if(!item.MemberRenewalNote?.trim()){
              item.MemberRenewalNote = "";
            }

            if (item.MemberRenewalAmount || item.MemberRenewalPeriod || item.MemberRenewalNote || item.MemberRenewalMonth || item.MemberRenewalDay) {
              item.RegistrationExits = 1;
            }




            if (item.ChildExits && item.ChildName1) {

              let child1 = {};

              if (item.RegistrationDetailExist1) {
                child1 = {
                  child_name: item.ChildName1,
                  registration_detail: item.RegistrationDetailExist1 ? true : false,
                  renewal_amount: item.ChildName1 ? item.RegistrationRenewalAmount : "",
                  renewal_period: item.ChildName1 ? item.RegistrationFrequency_MonthlyORYearly : "",
                  renewal_month: item.ChildName1 ? item.RegistrationRenewalMonth : "0",
                  renewal_day: item.ChildName1 ? item.RegistrationRenewalDayofMonth : "",
                  renewal_notes: item.ChildName1 ? item.Notes : ""
                };
              } else {
                child1 = { child_name: item.ChildName1 };
              }

              child_array.push(child1);
            }


            if (item.ChildExits && item.ChildName2) {

              let child2 = {};

              if (item.RegistrationDetailExist2) {

                child2 = {
                  child_name: item.ChildName2,
                  registration_detail: item.RegistrationDetailExist2 ? true : false,
                  renewal_amount: item.ChildName2 ? item.RegistrationRenewalAmount2 : "",
                  renewal_period: item.ChildName2 ? item.RegistrationFrequency_MonthlyORYearly2 : "",
                  renewal_month: item.ChildName2 ? item.RegistrationRenewalMonth2 : "0",
                  renewal_day: item.ChildName2 ? item.RegistrationRenewalDayofMonth2 : "",
                  renewal_notes: item.ChildName2 ? item.Notes2 : ""
                };
              }
              else {
                child2 = {
                  child_name: item.ChildName2
                };

              }

              child_array.push(child2);
            }



        

            let newObject = {
              auto_renewal: adminInfo?.auto_renewal,
              firstname: item.MemberFirstName,
              lastname: item.MemberLastName,    
              email: item.MemberEmailAddress,
              phoneno: item.MemberMobileNumber,
              zipcode: item.MemberZipCode,
              notification: item.PushNotificationEmail,
              child_exists: item.ChildExits,
              addChild: item.ChildExits,
              role: 'member',
              membership_status: "true",
              group_id: item.grp_id,
              registration_detail: item.RegistrationExits ? true : false,
              renewal_amount: item.ChildExits === 0 && item.RegistrationExits ? item.MemberRenewalAmount : "",
              renewal_period: item.ChildExits === 0 && item.RegistrationExits ? item.MemberRenewalPeriod.toLowerCase() : "",
              renewal_notes: item.ChildExits === 0 && item.RegistrationExits ? item.MemberRenewalNote : "",
              renewal_month: item.ChildExits === 0 && item.RegistrationExits ? item.MemberRenewalMonth : "",
              renewal_day: item.ChildExits === 0 && item.RegistrationExits ? item.MemberRenewalDay : "",
              child: child_array
            };

            const details = registrationDetails(newObject);
            File_data.push(details);
          });



          let bulk_data = { instituteid: instituteid, institutename: adminInfo?.institutename, 'memberArr': File_data };
          setFileStatus(1);

          setTimeout(() => {
          SchoolAdmin.verifyBulkMember(bulk_data)
            .then((res) => {
              if (res?.data?.success) {
                setFileStatus(2);
                setFileStatus(3);
                setTimeout(() => {
                SchoolAdmin.uploadBulkMember(bulk_data)
                  .then((res) => {
                    if (res?.data?.success) {
                      setFileStatus(4);                        
                      setTimeout(() => {  
                      handleClose();       
                      Swal.fire({ text: "File Uploaded Successfully", ...Success })
                      .then(() => {
                          window.location.href = '/manage-members'; // Redirect after 2 seconds
                      });
                    }, 2000);
                  
                   
                 
                    } else {
                      fileInputRef.current.value = null;
                      setFileName('Bulk Upload');
                      setFileStatus(0);
                      let message = res?.data?.message;
                      Swal.fire({ text: message ? message : Note.Err, ...Error, timer: 1000000 });
                    }
                  })
                  .catch((err) => {
                    const { message } = err?.response?.data;
                    Swal.fire({ text: message ? message : Note.Err, ...Error });
                    // setSubmitting(false);
                  });
                }, 500);
                
              } else {
                fileInputRef.current.value = null;
                setFileName('Bulk Upload');

                setFileStatus(0);
                let message = res?.data?.message;
                Swal.fire({ text: message ? message : Note.Err, ...Error, timer: 1000000 });
              }
            })
            .catch((err) => {
              const { message } = err?.response?.data;
              Swal.fire({ text: message ? message : Note.Err, ...Error });
              // setSubmitting(false);
            });
          }, 2000);
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  return (
  
    <Modal contentClassName="border-0" size="md" centered show={bulk} onHide={handleClose} keyboard={false}>
      <Modal.Header className="px-4 border-0" closeButton>
        <Modal.Title className="ms-auto event-title">Add Member</Modal.Title>
      </Modal.Header>

      <div className="modal-body">
        <div className="d-flex">
          <div className="modal-body">
            <div className="mb-4 ">
              <div to="#" className="select-group file-attach-box d-flex justify-content-between">
                <input type="file" name="file" ref={fileInputRef} className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                {fileName} <i className="ti ti-file-upload"></i>
              </div>
              <p>{FileStatus === 1 && 'Verifying your file'}</p>
              <p>{FileStatus === 3 && 'Uploading your file. Please wait..'}</p>
            </div>
            <div className="mb-4 ">
              <Link to="/add-members" state={data?.groupid ? { groupid: data?.groupid, role: "member" } : { role: "member" }} className="select-group d-flex justify-content-between">
                Add Member <i className="ti ti-user-plus"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddBulk;