import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Error, Note } from "../utils/Alert";
import DateFormat from "../utils/DateFormat";
import SchoolAdmin from "../services/admin.service";
import { getAllPosts } from "../features/postSlice";
import { open } from "../features/eventSlice";

const NotificationList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const notificationRef = useRef(null);
  const bellRef = useRef(null);
  const [data, setData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const { posts: allposts } = useSelector((state) => state?.posts);
  const { allInstitutesIds, profile } = useSelector((state) => state?.auth);
  const adminInstitute = profile?.roledata?.filter((item) => item?.role === "admin").map((item) => item?.instituteid);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);

  
  const fetchNotifications = () => {
    SchoolAdmin.viewNotifications()
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
      });
  };

  const toggleNotificationList = () => {
    if (!isOpen) {
      fetchNotifications();
    }
  };
  const dismissNotification = (data) => {
    SchoolAdmin.markNotificationSeen({ id: data?.id, postid: data?.postid })
      .then((response) => {
        if (notificationCount) {
          setNotificationCount(notificationCount - 1);
        }
        fetchNotifications();
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
      });
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef?.current &&
      !notificationRef.current.contains(event.target) &&
      bellRef?.current &&
      !bellRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    SchoolAdmin.notificationCount()
      .then((response) => {
        setNotificationCount(response?.data?.count);
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
      });

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);



  const handleRemindNotification = (notification) => {
console.log("Helo");
    const remindId = notification?.id
    setIsOpen(false);
    navigate('/home');
    dispatch(open({ event: "remindMe", data: { ...notification, id: notification?.postid, remindId } }));

  };
  function handleNotification(postid) {

    console.log('>>>>>>123>>>>>>>>>>', postid);

    if (postid) {
      navigate('/home');
      setTimeout(() => {
        dispatch(getAllPosts({ search: "", priorityIds: [postid], current_page: 1, institutes: allInstitutesIds, adminInstitute }));
      }, 50)


    }
  }

  return (
    <div className="notification-box" style={{ pointerEvents: auth?.welcomeScreen ? "unset" : "none" }}>
      <a className="notifications-btn open" onClick={() => { toggleNotificationList(); setIsOpen(!isOpen); }} ref={bellRef}>
        <i className="ti ti-bell"></i> {notificationCount > 0 && <small className='noti-content'>{notificationCount}</small>}
      </a>

      {isOpen && (
        <div className="notification-blog" ref={notificationRef}>
          <div className="notification-blog-head">
            <h4>Notifications</h4>
          </div>

          <div className="notification-blog-body">
            <ul className="notification-list">
              {data && data?.length > 0 ? (
                data?.map((notification) => (
                  <li key={notification?.id}>
                    <div className="notification-list-title" onClick={() => { notification?.type && handleNotification(notification?.postid); }}>
                      {notification?.postid ? <a><u style={{ color: '#374cce' }}><h4>{notification?.title}</h4></u></a> : <h4>{notification?.title}</h4>}
                      <small>{DateFormat(notification?.created_at, true)}</small>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <a className='notification-dismiss' onClick={() => { dismissNotification(notification) }}>Dismiss</a>
                      {notification?.type === 'reminder' &&
                        <a style={{ marginLeft: "14px" }} className={notification?.remind_me ? "bell-noti-blue ms-2 notification-dismiss" : "bell-noti ms-2 notification-dismiss"} onClick={() => handleRemindNotification(notification)} >
                          Remind Me
                        </a>
                      }
                    </div>
                  </li>
                ))
              ) : (
                <li>
                  <p>There are no new notifications.</p>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationList;
