import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import user from "../../../assets/images/DefaultUserIcon.png";
import { close } from "../../../features/eventSlice";
import { BASE } from "../../../utils/Constants";
import { PermissionCheck } from "../../../utils/Permissions";

const MemberCard = () => {
  const { state } = useLocation();
  const { card, data } = useSelector((state) => state?.event);
  const { adminInfo } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(close("card"));
  const profilePic = data?.profilepic ? BASE + "/" + data?.profilepic : user;
  useEffect(() => {
    return () => handleClose();
  }, []);
  console.log(data)
  return (
    <Modal size={adminInfo?.instituteid === state?.instituteid ? "md" : "sm"} centered show={card} onHide={handleClose} keyboard={false}>
      <Modal.Header className="border-0" closeButton />
      <div className="modal-body">
        <div className="text-center profile-head member-detail-info">
          <figure>
            <img 
                className="avatarimage" 
                src={profilePic} 
                alt="logo" 
                onError={(e) => {
                  e.target.src = user; 
                }}    
            />
          </figure>
          <figcaption>
            <h4>{data?.fullname}</h4>
            <span className="d-flex align-items-center justify-content-center ">
              <i className="ti ti-users"></i> {data?.grp_count} Group(s)
            </span>
          </figcaption>
        </div>
        <div className="home-btn-group d-flex justify-content-center mt-4 mb-4">
          <PermissionCheck instituteid={state?.instituteid} checkAdmin={true}>
            <Link to="/member-profile" state={{ id: data?.userid, memberid: data?.memberid }} className="black-btn me-2">
              View Profile
            </Link>
          </PermissionCheck>
          <Link to="/createmessage" state={{ userid: data?.userid }} className="blue-btn hvr-sweep-to-right ms-2">
            Add Personal Post
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default MemberCard;
