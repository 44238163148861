import { useEffect, useState } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import CardW from "../../components/wrapper/CardW";
import Post from "../../services/post.service";
import { Error, Note, Success } from "../../utils/Alert";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { dateTimeFormat, filterPassedTime } from "../../utils/DateFormat";
import { validationSchema } from "./DMValidationSchema";
import { TagsInput } from "react-tag-input-component";
import { beforeAddValidate } from "../../utils/ValidateTag";
import { AWS_BUCKET_NAME, supportedVideoFormats } from "../../utils/Constants";
import SchoolAdmin from "../../services/admin.service";
import AuthService from "../../services/auth.service";
import { TextCharacterLimitShow, TrimehandleChange, handleBlurTrim, handleFileChange } from "../../utils/FileSize";

const EditDM = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { selectedPost } = location?.state;
const [selectedPost , setSelectedPost] = useState(location?.state?.selectedPost)
  const [errorMsg, setErrormsg] = useState('');
  const [isDueDateDisabled, setIsDueDateDisabled] = useState(false);


  const upload_thumbnail_on_S3 = async (file, setFieldValue, setSubmitting) => {

    if (file && file.size > 1000000) {
      Swal.fire({ text: "File too large. Max size is 1MB.", icon: 'error' });
      setFieldValue("thumbnailValidation", "");
      setSubmitting(false);
      return;
    }
    setSubmitting(true);
    setFieldValue("thumbnailValidation", file)

    try {
      let url = '';
      let unique_filename = '';
      await SchoolAdmin.generatePresignedUrl({
        "filename": file?.name,
        "contentType": file?.type
      })
        .then((res) => {
          url = res?.data?.data?.url;
          unique_filename = res?.data?.data?.filename;
        })
        .catch((err) => {
          const { message } = err?.response?.data;
          Swal.fire({ text: message ? message : Note?.Err, ...Error });
        });

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file?.type
        },
        body: file
      });
      if (response.ok) {
        let path = `https://${AWS_BUCKET_NAME}.s3.amazonaws.com/`;
        setFieldValue("fileuploaded[0].thumbnail", path + unique_filename);
        console.log('Image uploaded successfully!');
      } else {
        console.error('Failed to upload image', response?.statusText);
      }
    } catch (err) {
      const { message } = err?.response?.data;
      Swal.fire({ text: message ? message : Note?.Err, ...Error });
    } finally {
    }
    setSubmitting(false);
  };



  const [fileSelected, setfileSelected] = useState("");
  const initialValues = {
    ...selectedPost,
    dmpost: true,
    title: selectedPost?.title || "",
    description: selectedPost?.description || "",
    disable_comments: selectedPost?.disable_comments || false,
    fileuploaded: [{
      path: selectedPost?.fileuploaded[0]?.path || "",
      mimetype: selectedPost?.fileuploaded[0]?.mimetype || "",
      thumbnail: selectedPost?.fileuploaded[0]?.thumbnail || ""
    }],
    fileValidation: "",
    thumbnailValidation: "",
    oldFile: selectedPost?.fileuploaded[0]?.path,
    oldThumbnail: selectedPost?.fileuploaded[0]?.thumbnail,
    instituteid: selectedPost?.instituteid,
    receiver_id: selectedPost?.receiver_id,
    start_date: new Date(selectedPost?.start_date) || new Date(),
    end_date: new Date(selectedPost?.end_date) || "",
    due_date: new Date(selectedPost?.due_date) || "",
    location: selectedPost?.location || "",
    price: selectedPost?.price,
    sales_tax: selectedPost?.sales_tax,
    colours: selectedPost?.colours || [],
    size: selectedPost?.size || [],
    rsvp: selectedPost?.rsvp || false,
    paid_events: selectedPost?.paid_events || false,
  };


  useEffect(() => {
    if (initialValues.start_date) {
      const startDateTime = new Date(initialValues.start_date);
      const now = new Date();
      setIsDueDateDisabled(startDateTime < now);
    }
  }, [initialValues.start_date]);

  const onSubmit = (values) => {
    const { fileuploaded, start_date, end_date, due_date, colours, size, ...rest } = values;
    // let formData = new FormData();
    // if (selectedPost?.fileuploaded[0]?.path === fileuploaded) {
    //   formData.append("fileuploaded", JSON.stringify(selectedPost?.fileuploaded));
    // } else if (fileuploaded instanceof File) {
    //   formData.append("fileuploaded", fileuploaded);
    // } else {
    //   formData.append("fileuploaded", JSON.stringify(fileuploaded));
    // }

    // formData.append("start_date", start_date ? start_date.toISOString() : "");
    // formData.append("end_date", end_date ? end_date.toISOString() : "");
    // formData.append("due_date", due_date ? due_date.toISOString() : "");
    // formData.append("colours", JSON.stringify(colours));
    // formData.append("size", JSON.stringify(size));
    // Object.keys(rest).forEach((k) => {
    //   if (typeof rest[k] === "string") {
    //     rest[k] = rest[k].trim().replace(/  +/g, " ");
    //     formData.append(k, rest[k]);
    //   } else {
    //     formData.append(k, JSON.stringify(rest[k]));
    //   }
    // });
    if (start_date || due_date) {
      const startDateTime = new Date(start_date);
      const dueDateTime = new Date(due_date);
      const startDay = `${startDateTime.getFullYear()}-${startDateTime.getMonth() + 1}-${startDateTime.getDate()}`;
      const dueDay = `${dueDateTime.getFullYear()}-${dueDateTime.getMonth() + 1}-${dueDateTime.getDate()}`;
      if (startDay === dueDay || !due_date) {
        dueDateTime.setTime(startDateTime.getTime() + 30 * 60 * 1000);
      } else if (startDateTime > dueDateTime) {
        dueDateTime.setHours(23, 59, 0);
      }
      values.due_date = dueDateTime;
    }

    values.start_date = values?.start_date ? start_date : "";
    values.end_date = values?.end_date ? end_date : "";
    values.due_date = values?.due_date ? due_date : "";
    values.colours = colours;
    values.size = size;


    Post.editPost(values)
      .then(() => {
        Swal.fire({ text: Note?.DMEdit, ...Success });
        navigate("/sendmessage");
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message, ...Error });
      });
  };

  useEffect(() => {
    if (!selectedPost) {
      navigate("/home");
    }
  }, [selectedPost, navigate]);

  if (!selectedPost) {
    return
  }

  return (
    <CardW>
      <h3 className="inner-title mb-4">Edit DM</h3>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, errors, setSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="custom-field one without-icon">
                <input className="form-control" type="text" disabled value={selectedPost?.renewalusers[0]?.fullname} />
                <span className="placeholder">Recipient</span>
              </label>
            </div>
            <div className="mb-4">
              <label className="custom-field one without-icon">
                <Field 
                     name="title" 
                     placeholder=" " 
                     onBlur={handleBlurTrim(handleChange)}
                     onChange={TrimehandleChange (setFieldValue)('title')}
                />
                <span className="placeholder">Post Title</span>
                <ErrorMessage component="p" className="text-danger" name="title" />
              </label>
            </div>
            <div className="mb-4 textarea-box">
              <label className="custom-field one without-icon">
                <textarea className="label-textarea" placeholder="Description" rows="5" name="description" onChange={handleChange} onBlur={handleBlurTrim(handleChange)} value={values?.description} />
                <ErrorMessage component="p" className="text-danger" name="description" />
              </label>
            </div>
            <div className="mb-4">
              <div className="file-upload-wrapper" data-text="Add Attachment">
                <input
                  name="fileValidation"
                  type="file"
                  className="file-upload-field"
                  onChange={(e) => handleFileChange(e, setFieldValue, setSubmitting, setErrormsg)}
                  // onChange={(e) => { setFieldValue("oldFile",""); upload_file_on_S3( e.target.files[0],setFieldValue , setSubmitting)}}
                  onClick={(e) => {
                    e.target.value = null;
                    setFieldValue("thumbnailValidation", ""); setFieldValue("fileuploaded[0].thumbnail", ""); setFieldValue("oldthumbnail", ""); setFieldValue("oldFile","");
                    setErrormsg('')
                  }}
                />
                <button>
                  <i className="ti ti-paperclip"></i>
                </button>
              </div>
              {/* <ErrorMessage component="p" className="custom-field text-danger" name="fileValidation" /> */}
              <div className="custom-field text-danger">
                {errorMsg}
              </div>

              {values?.oldFile && (
                <div className="text-success d-flex align-item-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                  {TextCharacterLimitShow(values?.oldFile,50)} file selected
                  <div
                    type="button"
                    className="delete-post ms-2"
                    onClick={() => {
                      setFieldValue("oldFile", "");
                      setFieldValue("oldthumbnail", "")
                      setFieldValue("fileuploaded[0].path", "");
                      setFieldValue("fileuploaded[0].mimetype", "");
                      setFieldValue("thumbnailValidation", ""); setFieldValue("fileuploaded[0].thumbnail", "")
                    }}
                  >
                    <i className="ti ti-trash"></i>
                  </div>
                </div>
              )}

              {!values?.fileValidation?.name && fileSelected && (
                <div className="text-success d-flex align-item-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                  {fileSelected?.replace("public/post/", "")} file selected
                  <div
                    type="button"
                    className="delete-post ms-2"
                    onClick={() => {
                      setfileSelected("");
                      setFieldValue("oldthumbnail", "")
                      setFieldValue("fileuploaded[0].path", "");
                      setFieldValue("fileuploaded[0].mimetype", "");
                      setFieldValue("thumbnailValidation", ""); setFieldValue("fileuploaded[0].thumbnail", "")
                    }}
                  >
                    <i className="ti ti-trash"></i>
                  </div>
                </div>
              )}

              {values?.fileValidation?.name && (
                <div className="text-success d-flex align-item-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                  {TextCharacterLimitShow(values?.fileValidation?.name,50)} file selected
                  <div
                    type="button"
                    className="delete-post ms-2"
                    onClick={() => {
                      setfileSelected("");
                      setFieldValue("fileValidation", "");
                      setFieldValue("fileuploaded[0].path", "");
                      setFieldValue("fileuploaded[0].mimetype", "");
                      setFieldValue("thumbnailValidation", ""); setFieldValue("fileuploaded[0].thumbnail", "")
                      setFieldValue("oldthumbnail", "")
                    }}
                  >
                    <i className="ti ti-trash"></i>
                  </div>
                </div>
              )}

              {!values?.oldThumbnail && (supportedVideoFormats.includes(values?.fileuploaded[0]?.mimetype)) && (
                <>
                  <div className="file-upload-wrapper" data-text="Add Thumbnail">
                    <input
                      name="thumbnailValidation"
                      type="file"
                      className="file-upload-field"
                      onChange={(e) => upload_thumbnail_on_S3(e.target?.files[0], setFieldValue, setSubmitting)}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      accept="image/*"
                    />
                    <button>
                      <i className="ti ti-paperclip"></i>
                    </button>
                  </div>
                  <ErrorMessage component="p" className="custom-field text-danger" name="thumbnailValidation" />

                  {values?.thumbnailValidation && (
                    <div className="text-success d-flex align-item-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                      {TextCharacterLimitShow(values?.thumbnailValidation?.name,50) + " file selected"}
                      <div className="delete-post ms-2" onClick={() => { setFieldValue("thumbnailValidation", ""); setFieldValue("fileuploaded[0].thumbnail", "") }}>
                        <i className="ti ti-trash"></i>
                      </div>
                    </div>
                  )}
                </>
              )}

              {values?.oldThumbnail && errors?.fileValidation == undefined && (supportedVideoFormats.includes(values?.fileuploaded[0]?.mimetype)) && (
                <>
                  <div className="file-upload-wrapper" data-text="Add Thumbnail">
                    <input
                      name="thumbnailValidation"
                      type="file"
                      className="file-upload-field"
                      onChange={(e) => upload_thumbnail_on_S3(e.target?.files[0], setFieldValue)}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      accept="image/*"
                    />
                    <button>
                      <i className="ti ti-paperclip"></i>
                    </button>

                  </div>
                  <ErrorMessage component="p" className="custom-field text-danger" name="thumbnailValidation" />

                  {values?.fileuploaded[0]?.thumbnail && (
                    <div className="text-success d-flex align-item-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                      {TextCharacterLimitShow(values?.fileuploaded[0]?.thumbnail,50) + " file selected"}
                      <div className="delete-post ms-2" onClick={() => { setFieldValue("oldThumbnail", ""); setFieldValue("fileuploaded[0].thumbnail", ""); }}>
                        <i className="ti ti-trash"></i>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="mb-4 d-flex">
              <label className="coustom-checkbox s-check-box me-4">
                <Field type="checkbox" name="disable_comments" />
                <span className="checkmark"></span>
                <p>Disable Comments</p>
              </label>
            </div>

            {selectedPost?.calendar_event ? (
              <>
                <h4 className="sub-title mb-1">Calendar Event</h4>
                <div className="tags-input-wrapper mb-4" style={{ border: "none" }}>
                  {values?.rsvp && <span className="tag">RSVP</span>}
                  {values?.paid_events && <span className="tag">PAID</span>}
                </div>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <DatePicker
                      placeholderText="Start Date"
                      selected={values?.start_date}
                      dateFormat={dateTimeFormat}
                      className="form-control"
                      name="start_date"
                      showTimeSelect
                      onChange={(date) => setFieldValue(`start_date`, date)}
                      minDate={values?.start_date}
                      filterTime={filterPassedTime}
                      onChangeRaw={(e) => e.preventDefault()}
                      autoComplete="off"
                    />
                    <ErrorMessage component="p" className="custom-field text-danger" name="start_date" />
                  </div>

                  <div className="col-md-6 mb-4">
                    <DatePicker
                      placeholderText="End Date"
                      selected={values?.end_date}
                      dateFormat={dateTimeFormat}
                      className="form-control"
                      name="end_date"
                      showTimeSelect
                      onChange={(date) => setFieldValue(`end_date`, date)}
                      minDate={values?.start_date}
                      filterTime={filterPassedTime}
                      onChangeRaw={(e) => e.preventDefault()}
                      autoComplete="off"
                    />
                    <ErrorMessage component="p" className="custom-field text-danger" name="end_date" />
                  </div>

                  <div className="col-md-12 mb-4">
                    <label className="custom-field one without-icon">
                      <Field type="text" name="location" onBlur={handleBlurTrim(handleChange)}/>
                      <span className="placeholder">Location</span>
                      <ErrorMessage component="p" className="text-danger" name="location" />
                    </label>
                  </div>
                </div>

                {values?.paid_events ? (
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="with-icon">
                        <i className="ti ti-currency-dollar"></i>
                        <Field type="text" name="paid_event_amount" placeholder="Enter amount" className="form-control" />
                      </div>
                      <ErrorMessage component="p" className="text-danger" name="paid_event_amount" />
                    </div>
                    <div className="col-md-6 mb-4">
                      <DatePicker
                        placeholderText="Due Date"
                        selected={values?.due_date.setHours(0,0,0)} 
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                        disabled={isDueDateDisabled}
                        name="due_date"
                        onChange={(date) => {setFieldValue('due_date', date);
                          date.setHours(0,0,0)
                        }}
                        minDate={new Date()}
                        onChangeRaw={(e) => e.preventDefault()}
                        autoComplete="off"
                      />
                      <ErrorMessage component="p" className="text-danger" name="due_date" />
                    </div>
                  </div>
                ) : null}
                <div className="mb-4 d-flex">
                  <label className="coustom-checkbox s-check-box me-4">
                    <Field type="checkbox" name="is_cancelled" />
                    <span className="checkmark"></span>
                    <p>Cancel Event</p>
                  </label>
                </div>
              </>
            ) : null}
            {selectedPost?.merchandise_sale ? (
              <>
                <h4 className="sub-title mb-4">Merchandise Sale</h4>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="with-icon">
                      <i className="ti ti-currency-dollar"></i>
                      <Field type="text" name="price" placeholder="Price" className="form-control" onBlur={handleBlurTrim(handleChange)}/>
                    </div>
                    <ErrorMessage component="p" className="text-danger" name="price" />
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="with-icon">
                      <i className="ti ti-currency-dollar"></i>
                      <Field type="text" name="sales_tax" placeholder="Estimated Sales Tax" className="form-control" onBlur={handleBlurTrim(handleChange)}/>
                    </div>
                    <ErrorMessage component="p" className="text-danger" name="sales_tax" />
                  </div>
                </div>

                <div className="mb-4 ">
                  <TagsInput
                    name="colours"
                    value={values?.colours}
                    onChange={(values) => setFieldValue("colours", values)}
                    beforeAddValidate={beforeAddValidate}
                    separators={[","]}
                    placeHolder={values?.colours?.length ? "" : "Pick color (use comma to enter multiple color options)"}
                  />
                  <ErrorMessage component="p" className="text-danger" name="colours" />
                </div>
                <div className="mb-4">
                  <TagsInput
                    name="size"
                    value={values.size}
                    onChange={(values) => setFieldValue("size", values)}
                    beforeAddValidate={beforeAddValidate}
                    separators={[","]}
                    placeHolder={values?.size?.length ? "" : "Pick size (use comma to enter multiple size options)"}
                  />
                  <ErrorMessage component="p" className="text-danger" name="size" />
                </div>
              </>
            ) : null}
            <div className="mt-4 d-flex justify-content-center">
              <button type="button" className="btn min-btn black-btn hvr-sweep-to-right me-2" onClick={() => navigate(-1)}>
                Cancel
              </button>
              <button type="submit" disabled={isSubmitting} className="btn min-btn hvr-sweep-to-right">
                Edit & Post
              </button>
            </div>
          </form>
        )}
      </Formik>
    </CardW>
  );
};

export default EditDM;
